import { CONTAINER_RESIZE_THRESHOLD } from "../../common/PivotConsts";
import { debounce } from "../../common/PivotUtils";
import { DomDrawState } from "../common/DomDrawState";
import IDrawPivot from "./IDrawPivot";

export default class PivotTableSize {
  private drawPivotDataIntf: IDrawPivot;
  private pivotTableDOM: HTMLElement;

  constructor(drawPivotDataIntf: IDrawPivot, pivotTableDOM: HTMLElement) {
    this.drawPivotDataIntf = drawPivotDataIntf;
    this.pivotTableDOM = pivotTableDOM;
  }

  private debounceDraw = debounce(() => {
    this.drawPivotDataIntf.draw();
  }, 150);

  public doObserve() {
    const tableSize = DomDrawState.value().tableSize;
    if (!tableSize.observerInitialized) {
      this.initResizeObserver();
      tableSize.observerInitialized = true;
    }
  }

  private initResizeObserver() {
    const resizeObserver = new ResizeObserver((entries) => {
      const tableSize = DomDrawState.value().tableSize;
      if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0) {
        const widthInt = Math.floor(entries[0].contentRect.width);
        const heightInt = Math.floor(entries[0].contentRect.height);

        if (
          Math.abs(tableSize.currentWidth - widthInt) > CONTAINER_RESIZE_THRESHOLD ||
          Math.abs(tableSize.currentHeight - heightInt) > CONTAINER_RESIZE_THRESHOLD
        ) {
          tableSize.currentWidth = widthInt;
          tableSize.currentHeight = heightInt;
          tableSize.sizeChanged = true;
        }
        tableSize.hasSpaceForDraw = true;
      } else {
        tableSize.hasSpaceForDraw = false;
      }
      this.debounceDraw();
    });

    resizeObserver.observe(this.pivotTableDOM);
  }
}
