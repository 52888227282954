import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import { INVALID_CREDENTIALS, INVALID_RECAPTCHA } from "@/core/common/utils/ResourceStrings";
import { Login } from "../domain/Login";
import { LoginResponse } from "../domain/LoginResponse";

interface APILoginResponse {
  success: boolean;
  errorMsg: string;
  data: { userId: string; authSuccess: boolean; recaptchaSuccess: boolean; detail: string };
}

export class LoginImpl extends Login {
  private httpClient: HTTPClient;
  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
  }

  public async doLogout(): Promise<boolean> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/logout", {});

    return Promise.resolve(httpResponse.success);
  }

  public async doLogin(mail: string, password: string, captchaToken: string): Promise<LoginResponse> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/login", {
      mail,
      password,
      captchaToken,
    });
    const result = {} as LoginResponse;

    if (httpResponse.success) {
      const apiResponse = (<APILoginResponse>httpResponse).data;
      if (!apiResponse.recaptchaSuccess) {
        result.success = false;
        result.errorMsg = INVALID_RECAPTCHA + " - " + apiResponse.detail;
        result.userId = "";
      } else if (!apiResponse.authSuccess) {
        result.success = false;
        result.errorMsg = INVALID_CREDENTIALS;
        result.userId = "";
      } else {
        result.success = true;
        result.errorMsg = "";
        result.userId = (<APILoginResponse>httpResponse).data.userId;
      }
    } else {
      result.success = false;
      result.errorMsg = httpResponse.errorMsg;
    }

    return Promise.resolve(result);
  }
}
