<template>
  <div class="pivot-root section-content">
    <div class="pivot-opts-bar">
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="doExportCSV()"
      >
        <download-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getFormatsModalId())"
      >
        <font-size-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getOrderingModalId())"
      >
        <swap-outlined :rotate="90"></swap-outlined>
      </div>
      <div
        :class="{
          'opt-item': true,
          disabled: state.loading || state.error,
          enabled: !state.loading && !state.error,
          filtered: isFiltered(),
        }"
        @click="openOptions(getFiltersModalId())"
      >
        <filter-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getEntitiesModalId())"
      >
        <deployment-unit-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getSettingsModalId())"
      >
        <setting-outlined />
      </div>
    </div>
    <div class="loader-container" v-show="state.loading && !state.error"><Loader /></div>
    <div ref="pivotTable" class="pivot-table" v-show="!state.loading && !state.emptyData && !state.error">
      <div class="pivot-viewport">
        <div class="pivot-scrollbar-box">
          <div class="pivot-alignment-box">
            <div class="pivot-data-values"></div>
          </div>
          <div class="pivot-full-height-width"></div>
        </div>
        <div class="pivot-col-head">
          <div class="pivot-col-head-entities-metadata cell cell-header"></div>
          <div class="pivot-col-head-hscroll-viewport">
            <div class="pivot-col-head-hscroll-hidden">
              <div class="pivot-col-head-alignment-box">
                <div class="pivot-col-head-data"></div>
              </div>
              <div class="pivot-col-head-full-height-width"></div>
            </div>
          </div>
        </div>
        <div class="pivot-row-head">
          <div class="pivot-row-head-entities-metadata"></div>
          <div class="pivot-row-head-viewport">
            <div class="pivot-row-head-vscroll-hidden">
              <div class="pivot-row-head-alignment-box">
                <div class="pivot-row-head-data"></div>
              </div>
              <div class="pivot-row-head-full-height-width"></div>
            </div>
          </div>
        </div>
      </div>
      <canvas id="cellWidthCalculator" style="display: none"></canvas>
      <div class="pivot-tooltip"></div>
    </div>
    <div v-show="state.emptyData && !state.error && !state.loading" class="status-icon-container">
      <div class="warning-icon"><warning-outlined></warning-outlined></div>

      <span>Non ci sono dati da mostrare</span>
    </div>
    <div v-show="state.error && !state.emptyData" class="status-icon-container">
      <div class="error-icon"><exclamation-circle-outlined></exclamation-circle-outlined></div>

      <span>Errore durante il caricamento della pivot, consultare i log per dettagli</span>
    </div>
  </div>
  <pivot-settings v-if="state.modalVisible && state.modalId === getSettingsModalId()"> </pivot-settings>
  <pivot-entities v-if="state.modalVisible && state.modalId === getEntitiesModalId()"></pivot-entities>
  <pivot-ordering v-if="state.modalVisible && state.modalId === getOrderingModalId()"></pivot-ordering>
  <pivot-formats v-if="state.modalVisible && state.modalId === getFormatsModalId()"></pivot-formats>
  <pivot-filters v-if="state.modalVisible && state.modalId === getFiltersModalId()"></pivot-filters>
</template>

<script lang="ts">
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import { defineComponent, onMounted, ref, inject, onUnmounted } from "vue";
import {
  SettingOutlined,
  DeploymentUnitOutlined,
  FilterOutlined,
  DownloadOutlined,
  FontSizeOutlined,
  SwapOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import Loader from "../../controls/Loader.vue";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import PivotSettings from "./PivotSettings.vue";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import PivotEntities from "./PivotEntities.vue";
import PivotOrdering from "./PivotOrdering.vue";
import PivotFormats from "./PivotFormats.vue";
import PivotFilters from "./PivotFilters.vue";
import {
  ENTITIES_MODAL_ID,
  FILTERS_MODAL_ID,
  FORMATS_MODAL_ID,
  ORDERING_MODAL_ID,
  SETTINGS_MODAL_ID,
} from "@/core/pivot/common/PivotConsts";

export default defineComponent({
  components: {
    SettingOutlined,
    DeploymentUnitOutlined,
    FilterOutlined,
    DownloadOutlined,
    FontSizeOutlined,
    SwapOutlined,
    Loader,
    PivotSettings,
    PivotEntities,
    PivotOrdering,
    PivotFormats,
    PivotFilters,
    WarningOutlined,
    ExclamationCircleOutlined,
  },

  setup() {
    //Init component
    const pivotTable = ref<HTMLElement>();
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);

    const doExportCSV = () => {
      pivotPLOC.doExportCSV();
    };

    const openOptions = (modalId: string) => {
      pivotPLOC.openOptionsModal(modalId);
    };

    onMounted(async () => {
      if (pivotTable.value) {
        const result = await pivotPLOC.setupPivot(pivotTable.value);
        if (!result.success) console.error("Pivot.vue/onMounted/setupPivot: cannot load pivot data " + result.errorMsg);
      } else console.error("Pivot.vue/onMounted: cannot mount pivot, pivotContainer not found.");
    });

    const getSettingsModalId = () => {
      return SETTINGS_MODAL_ID;
    };
    const getEntitiesModalId = () => {
      return ENTITIES_MODAL_ID;
    };
    const getOrderingModalId = () => {
      return ORDERING_MODAL_ID;
    };
    const getFormatsModalId = () => {
      return FORMATS_MODAL_ID;
    };
    const getFiltersModalId = () => {
      return FILTERS_MODAL_ID;
    };
    const isFiltered = () => {
      return pivotPLOC.isFiltered();
    };

    onUnmounted(() => {
      pivotPLOC.unMountPivot();
    });
    return {
      pivotTable,
      state: pivotState,
      openOptions,
      doExportCSV,
      getSettingsModalId,
      getEntitiesModalId,
      getOrderingModalId,
      getFormatsModalId,
      getFiltersModalId,
      isFiltered,
    };
  },
});
</script>

<style lang="scss">
@import "../../../../../public/styles/variables.scss";

$opts-bar-height: 30px;

.pivot-root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--pivot-root-bg-color);

  //costanti
  --pivot-root-bg-color: #ffffff;
  --col-head-hscroll-hidden-space: 50px;
  --pivot-header-bg-color: #edf1f7;
  --pivot-leaves-bg-color: #f0f3ff;
  --pivot-subtotals-bg-color: #e6ecff;
  --pivot-totals-bg-color: #ccd9ff;

  //variabili popolate a runtime
  --row-head-width: ;
  --row-head-start-height: ;
  --all-data-cols-width: ;
  --cell-height: ;
  --all-data-rows-number: ;
  --col-head-data-height: ;
  --col-head-template-cols: ;
  --row-head-template-cols: ;
  --rows-head-full-width: ;
  --visible-data-rows-number: ;

  .loader-container {
    height: 100%;
  }

  .pivot-opts-bar {
    height: $opts-bar-height;
    background: white;
    border-bottom: 1px solid $gray-300;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    @media only screen and (max-width: 950px) {
      padding-right: 10px;
    }

    .opt-item {
      height: 20px;
      width: 50px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-600;
      border-left: 1px solid $gray-300;
      padding-left: 20px;
      margin-right: 20px;

      &:hover {
        color: $selected-color;
      }

      &.filtered {
        color: rgb(255, 208, 0);
      }

      @media only screen and (max-width: 600px) {
        padding-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .pivot-table {
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    .pivot-viewport {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .pivot-scrollbar-box {
      position: relative;
      display: inline-block;
      margin-top: var(--row-head-start-height);
      margin-left: var(--row-head-width);
      max-width: calc(100% - var(--row-head-width));
      max-height: calc(100% - var(--row-head-start-height));
      overflow: auto;
      overscroll-behavior: none;

      .pivot-alignment-box {
        overflow: visible;
        position: sticky;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
      }

      .pivot-full-height-width {
        display: block;
        width: var(--all-data-cols-width);
        height: calc(var(--all-data-rows-number) * var(--cell-height));
        z-index: -1;
      }
    }

    .pivot-col-head {
      position: absolute;
      left: var(--row-head-width);
      width: var(--all-data-cols-width);
      max-width: calc(100% - var(--row-head-width));
      height: var(--row-head-start-height);
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: inset -1px 0 var(--pivot-root-bg-color);
      background-color: var(--pivot-header-bg-color);
      top: 0;

      .pivot-col-head-hscroll-viewport {
        width: var(--all-data-cols-width);
        max-width: 100%;
        height: var(--col-head-data-height);
        overflow: hidden;
        --col-head-data-height: calc(var(--col-head-data-rows-number) * var(--cell-height));

        .pivot-col-head-hscroll-hidden {
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          height: calc(var(--col-head-data-height) + var(--col-head-hscroll-hidden-space));
          position: relative;

          .pivot-col-head-alignment-box {
            overflow: visible;
            position: sticky;
            width: 0;
            height: 0;
            left: 0;
            top: 0;

            .pivot-col-head-data {
              grid-template-rows: repeat(var(--col-head-data-rows-number), var(--cell-height));
              grid-template-columns: var(--col-head-template-cols);
            }
          }

          .pivot-col-head-full-height-width {
            width: var(--all-data-cols-width);
            height: var(--col-head-data-height);
            content: "";
            display: block;
          }
        }
      }

      .pivot-col-head-entities-metadata {
        position: relative;
        z-index: 3;
        border-top-color: var(--pivot-root-bg-color);
        border-width: calc(1px * var(--showHeaderColumnDimensionName));
        height: calc(var(--cell-height) * var(--showHeaderColumnDimensionName));
        border-top: none;
      }
    }

    .pivot-row-head-data,
    .pivot-col-head-data,
    .pivot-data-values {
      display: grid;
      grid-gap: 0;
      will-change: transform;
      transform: translate(0px, 0px);
      background: var(--pivot-leaves-bg-color);
    }

    .pivot-row-head {
      left: 0;
      top: 0;
      max-height: 100%;
      width: var(--row-head-width);
      background-color: var(--pivot-header-bg-color);
      overflow-x: auto;
      overflow-y: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;

      .pivot-row-head-entities-metadata {
        flex: 0 0 auto;
        display: grid;
        align-items: end;
        position: relative;
        height: var(--row-head-start-height);
        width: var(--row-head-full-width);
        grid-template-columns: var(--row-head-template-cols);
        background-color: var(--pivot-header-bg-color);
        box-shadow: inset -1px 0px 0 var(--pivot-root-bg-color);
        z-index: 3;

        .cell {
          box-shadow: 0px -1px 0 var(--pivot-root-bg-color);
          background: none;
          &:first-child {
            border-left-color: var(--pivot-root-bg-color);
          }
        }
      }

      .pivot-row-head-viewport {
        width: var(--row-head-full-width);
        flex: 0 1 auto;
        max-height: 100%;
        overflow: hidden;
        height: calc(var(--all-data-rows-number) * var(--cell-height));
        display: flex;
        flex-direction: column;

        .pivot-row-head-vscroll-hidden {
          height: 100%;
          width: calc(var(--row-head-full-width) + 50px);
          overflow: auto;
          position: relative;

          .pivot-row-head-alignment-box {
            width: 0;
            height: 0;
            overflow: visible;
            position: sticky;
            left: 0;
            top: 0;

            .pivot-row-head-data {
              grid-template-columns: var(--row-head-template-cols);
              grid-template-rows: repeat(var(--visible-data-rows-number), var(--cell-height));
            }
          }

          .pivot-row-head-full-height-width {
            display: block;
            height: calc(var(--all-data-rows-number) * var(--cell-height));
            content: "";
          }
        }
      }
    }

    .pivot-data-values {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      grid-template-columns: var(--col-head-template-cols);
      grid-template-rows: repeat(var(--visible-data-rows-number), var(--cell-height));
      box-shadow: 0 1px 0 var(--pivot-root-bg-color);
    }

    .cell {
      border: 1px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: var(--cell-height);
      line-height: var(--cell-height);
      padding: 0 8px;
      box-sizing: border-box;
      text-rendering: optimizeLegibility;
      font-size: 14px;
      text-align: right;
      border-bottom-color: var(--pivot-root-bg-color);
      border-right-color: var(--pivot-root-bg-color);

      &.close::before,
      &.open::before {
        content: "▸";
        padding-right: 5px;
        cursor: pointer;
      }
      &.open::before {
        content: "▾";
      }

      &.cell-header {
        font-weight: 600;
        text-align: left;
      }

      &.row-head-cell:not(.measure) {
        border-bottom-color: transparent;
      }

      &.row-head-cell.first-of-row {
        border-left-color: var(--pivot-root-bg-color);
      }

      &.row-head-cell.last-of-list {
        border-bottom-color: var(--pivot-root-bg-color);
      }

      &.row-head-cell.new-cell {
        box-shadow: 0 -1px 0 var(--pivot-root-bg-color);
      }

      &.row-head-cell.no-division-border {
        border-right-color: transparent;
      }

      &.col-head-cell:not(.measure) {
        border-right-color: transparent;
      }

      &.col-head-cell.new-cell {
        box-shadow: -1px 0 0 var(--pivot-root-bg-color);
      }

      &.col-head-cell.no-division-border {
        border-top-color: transparent;
      }

      &.col-head-cell.last-column-of-row {
        border-right-color: var(--pivot-root-bg-color);
      }

      &.subtotal-cell {
        background-color: var(--pivot-subtotals-bg-color);
      }
      &.total-cell {
        background-color: var(--pivot-totals-bg-color);
      }

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }
    }

    .pivot-tooltip {
      position: fixed;
      visibility: hidden;
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      z-index: 999;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}
</style>
