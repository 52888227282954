import { CELL_HEIGHT, COLUMN_WIDTH } from "../../common/PivotConsts";
import { DomDrawState } from "../common/DomDrawState";
import IDrawPivot from "./IDrawPivot";

export default class PivotScroll {
  private pivotScrollbarDOM: HTMLElement;
  private pivotRowHeadVScrollDOM: HTMLElement;
  private pivotColHeadHScrollDOM: HTMLElement;
  private scrollMaster: string;
  private timerStopRender: number;
  private drawPivotDataIntf: IDrawPivot;

  constructor(
    pivotScrollbarDOM: HTMLElement,
    pivotRowHeadVScrollDOM: HTMLElement,
    pivotColHeadHScrollDOM: HTMLElement,
    drawPivotDataIntf: IDrawPivot
  ) {
    this.pivotScrollbarDOM = pivotScrollbarDOM;
    this.pivotColHeadHScrollDOM = pivotColHeadHScrollDOM;
    this.pivotRowHeadVScrollDOM = pivotRowHeadVScrollDOM;
    this.scrollMaster = "";
    this.timerStopRender = -1;
    this.drawPivotDataIntf = drawPivotDataIntf;
  }

  calculateScrollInfo() {
    const scroll = DomDrawState.value().scroll;
    scroll.isNewRow = false;
    scroll.isNewColumn = false;

    const currentScrollTop = this.pivotScrollbarDOM.scrollTop;
    const currentScrollLeft = this.pivotScrollbarDOM.scrollLeft;

    const firstRowAbs = currentScrollTop < 0 ? 0 : Math.floor(currentScrollTop / CELL_HEIGHT);
    const firstColumnAbs = currentScrollLeft < 0 ? 0 : Math.floor(currentScrollLeft / COLUMN_WIDTH);

    if (firstRowAbs !== scroll.firstRowAbsolute) {
      scroll.firstRowAbsolute = firstRowAbs;
      scroll.isNewRow = true;
    }

    if (scroll.firstColumnAbsolute !== firstColumnAbs) {
      scroll.firstColumnAbsolute = firstColumnAbs;
      scroll.isNewColumn = true;
    }

    scroll.translateRow = Math.ceil(-currentScrollTop % CELL_HEIGHT);
    scroll.translateColumn = Math.ceil(-currentScrollLeft % COLUMN_WIDTH);
  }

  private resetScrollMaster() {
    this.scrollMaster = "";
  }

  public attachScrollEvents() {
    this.pivotScrollbarDOM.addEventListener(
      "scroll",
      () => {
        this.startDataScrollAnimation();
        clearTimeout(this.timerStopRender);
        this.timerStopRender = window.setTimeout(this.stopDataScrollAnimation, 100);
        if (!this.scrollMaster || this.scrollMaster === "data") {
          this.scrollMaster = "data";
          this.pivotRowHeadVScrollDOM.scrollTop = this.pivotScrollbarDOM.scrollTop;
          this.pivotColHeadHScrollDOM.scrollLeft = this.pivotScrollbarDOM.scrollLeft;
        }
      },
      { passive: true }
    );

    this.pivotRowHeadVScrollDOM.addEventListener(
      "scroll",
      () => {
        if (!this.scrollMaster || this.scrollMaster === "headerRow") {
          this.scrollMaster = "headerRow";
          this.pivotScrollbarDOM.scrollTop = this.pivotRowHeadVScrollDOM.scrollTop;
        }
      },
      { passive: true }
    );

    this.pivotColHeadHScrollDOM.addEventListener(
      "scroll",
      () => {
        if (!this.scrollMaster || this.scrollMaster === "headerColumn") {
          this.scrollMaster = "headerColumn";
          this.pivotScrollbarDOM.scrollLeft = this.pivotColHeadHScrollDOM.scrollLeft;
        }
      },
      { passive: true }
    );

    this.pivotScrollbarDOM.addEventListener(
      "touchstart",
      () => {
        this.pivotScrollbarDOM.scrollTop = this.pivotRowHeadVScrollDOM.scrollTop;
        this.resetScrollMaster();
      },
      { passive: true }
    );

    this.pivotRowHeadVScrollDOM.addEventListener(
      "touchstart",
      () => {
        this.pivotScrollbarDOM.scrollTop = this.pivotRowHeadVScrollDOM.scrollTop;
        this.resetScrollMaster();
      },
      { passive: true }
    );

    this.pivotColHeadHScrollDOM.addEventListener(
      "touchstart",
      () => {
        this.pivotScrollbarDOM.scrollLeft = this.pivotColHeadHScrollDOM.scrollLeft;
        this.resetScrollMaster();
      },
      { passive: true }
    );

    this.pivotScrollbarDOM.addEventListener("mouseleave", this.resetScrollMaster, { passive: true });
    this.pivotRowHeadVScrollDOM.addEventListener("mouseleave", this.resetScrollMaster, { passive: true });
    this.pivotColHeadHScrollDOM.addEventListener("mouseleave", this.resetScrollMaster, { passive: true });
  }

  private startDataScrollAnimation = () => {
    if (!DomDrawState.value().scroll.dataScrollAnimationIsRunning) {
      DomDrawState.value().scroll.dataScrollAnimationIsRunning = true;
      window.requestAnimationFrame(this.drawPivotDataIntf.drawPivotData.bind(this.drawPivotDataIntf));
    }
  };

  private stopDataScrollAnimation = () => {
    DomDrawState.value().scroll.dataScrollAnimationIsRunning = false;
    this.resetScrollMaster();
  };
}
