import { TOGGLE_CELL_OPENING_MSG } from "../../common/PivotConsts";
import IWorkerPostMessage from "../../worker/IWorkerPostMessage";
import PivotNodeBubblingIterator from "./PivotNodeBubblingIterator";
import { PivotTooltip } from "./PivotTooltip";

export default class DomEventsHandler {
  private worker: IWorkerPostMessage;
  private tooltipManager: PivotTooltip;

  constructor(worker: IWorkerPostMessage, tooltipManager: PivotTooltip) {
    this.worker = worker;
    this.tooltipManager = tooltipManager;
  }

  private onNodeClick(node: HTMLElement, isInteractive: boolean, onRow: boolean) {
    if (isInteractive) {
      const headPath = node.getAttribute("data-head-path");
      if (isInteractive && !!headPath) {
        this.worker.postMessage({
          type: TOGGLE_CELL_OPENING_MSG,
          props: { onRow, headPath },
        });
      }
    }
  }

  private onNodeMouseOver(node: HTMLElement, isInteractive: boolean, onRow: boolean) {
    if (isInteractive) {
      if (node.classList.contains("need-tooltip")) this.tooltipManager.show(node, node.textContent || "");
      else this.tooltipManager.hide();
    } else {
      this.tooltipManager.hide();
    }
  }

  public handleClick(event: Event) {
    const node: HTMLElement | null = (event?.target as HTMLElement) || null;
    PivotNodeBubblingIterator.iterateNode(node, this.onNodeClick.bind(this));
  }

  public handleMouseOver(event: Event) {
    const node: HTMLElement | null = (event?.target as HTMLElement) || null;
    PivotNodeBubblingIterator.iterateNode(node, this.onNodeMouseOver.bind(this));
  }
}
