import {
  FULL_USER_FORMAT,
  INITIALS_USER_FORMAT,
  LONG_DATE_FORMAT,
  LONG_MONTH_DATE_FORMAT,
  LONG_MONTH_YEAR_DATE_FORMAT,
  MONTH_2DIGIT_YEAR_DATE_FORMAT,
  ONLY_NAME_USER_FORMAT,
  ONLY_SURNAME_USER_FORMAT,
  SHORT_MONTH_DATE_FORMAT,
  SHORT_MONTH_YEAR_DATE_FORMAT,
} from "../../common/PivotConsts";
import { INumberFormatOptions } from "../PivotOptionsDS";

export default abstract class PivotFormatter {
  public abstract formatDimension(codeStr: string, entityAlias: string, entityFormat: string): string;

  public abstract formatNumber(options: INumberFormatOptions, value: number): string;
}

export const USERS_FORMATS = [FULL_USER_FORMAT, ONLY_NAME_USER_FORMAT, ONLY_SURNAME_USER_FORMAT, INITIALS_USER_FORMAT];

export const DATE_FORMATS = [
  LONG_DATE_FORMAT,
  SHORT_MONTH_DATE_FORMAT,
  LONG_MONTH_DATE_FORMAT,
  MONTH_2DIGIT_YEAR_DATE_FORMAT,
  SHORT_MONTH_YEAR_DATE_FORMAT,
  LONG_MONTH_YEAR_DATE_FORMAT,
];
