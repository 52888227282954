<template>
  <div class="internal-app">
    <Iconbar></Iconbar>
    <div class="internal-app-content">
      <PageHeader></PageHeader>

      <div v-if="appState.currentPage === getCalendarPageId()">
        <calendar></calendar>
      </div>
      <div v-if="appState.currentPage === getAnalysisPageId()">
        <analysis></analysis>
      </div>
      <div v-if="appState.currentPage === getUsersPageId()">
        <users-list></users-list>
      </div>
      <div v-if="appState.currentPage === getPerformancesPageId()">
        <performances-list></performances-list>
      </div>

      <notifications-column></notifications-column>
      <user-detail v-show="usersSate.loggedUserDetailVisible"></user-detail>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, provide } from "vue";
import Iconbar from "./navigation/Iconbar.vue";
import { manageSubscription } from "../common/SubscriptionManager";
import NotificationsColumn from "./notifications/NotificationsColumn.vue";
import {
  APP_PLOC_KEY,
  CALENDAR_PLOC_KEY,
  FACTORY_KEY,
  HTTP_CLIENT_KEY,
  LOGIN_PLOC_KEY,
  NOTIFICAITONS_PLOC_KEY,
  PERFORMANCES_PLOC_KEY,
  PIVOT_PLOC_KEY,
  USERS_PLOC_KEY,
} from "../common/GUIConsts";
import { AppPLOC } from "../../core/app-navigation/presentation/AppPLOC";
import Calendar from "./calendar/Calendar.vue";
import {
  ANALYSIS_PAGE_ID,
  CALENDAR_PAGE_ID,
  PERFORMANCES_PAGE_ID,
  SPLASH_SCREEN_PAGE_ID,
  USERS_PAGE_ID,
} from "../../core/app-navigation/domain/PagesConsts";
import PageHeader from "./navigation/PageHeader.vue";
import { Factory } from "@/core/Factory";
import { HTTPClient } from "@/core/common/communication/HTTPClient";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";
import Analysis from "./analysis/Analysis.vue";
import UsersList from "./users/UsersList.vue";
import PerformancesList from "./perfomances/PerformancesList.vue";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";
import UserDetail from "./users/UserDetail.vue";
import WindowResize from "@/core/common/utils/WindowResize";
export default defineComponent({
  components: {
    Iconbar,
    Calendar,
    PageHeader,
    Analysis,
    UsersList,
    PerformancesList,
    NotificationsColumn,
    UserDetail,
  },
  setup() {
    const httpClient = inject(HTTP_CLIENT_KEY) as HTTPClient;
    const appPLOC = inject(APP_PLOC_KEY) as AppPLOC;
    const appState = manageSubscription(appPLOC);
    const factory = inject(FACTORY_KEY) as Factory;
    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const usersRepository = factory.createUsersRepository(httpClient);
    const usersPLOC = factory.createUsersPLOC(usersRepository);
    const usersSate = manageSubscription(usersPLOC);

    const performancesRepository = factory.createPerformancesRepostory(httpClient);
    const eventsRepository = factory.createEventsRepository(httpClient);
    const calendarOptions = factory.createCalendarOptions(usersRepository, performancesRepository, eventsRepository);
    const calendarPLOC = factory.createCalendarPLOC(calendarOptions, eventsRepository, usersRepository);

    const performancesPLOC = factory.createPerformancesPLOC(performancesRepository);
    const indexer = factory.createPivotData(httpClient);
    const pivotPLOC = factory.createPivotPLOC(performancesPLOC, indexer, usersPLOC);
    const notificationsPLOC = factory.createNotificationsPLOC();

    provide(PIVOT_PLOC_KEY, pivotPLOC);
    provide(CALENDAR_PLOC_KEY, calendarPLOC);
    provide(USERS_PLOC_KEY, usersPLOC);
    provide(PERFORMANCES_PLOC_KEY, performancesPLOC);
    provide(NOTIFICAITONS_PLOC_KEY, notificationsPLOC);

    const getCalendarPageId = () => {
      return CALENDAR_PAGE_ID;
    };

    const getAnalysisPageId = () => {
      return ANALYSIS_PAGE_ID;
    };

    const getUsersPageId = () => {
      return USERS_PAGE_ID;
    };

    const getPerformancesPageId = () => {
      return PERFORMANCES_PAGE_ID;
    };

    onMounted(async () => {
      WindowResize.getInstance().setup();

      const usersLoading = await usersPLOC.loadUsers();
      const notificationsManager = NotificationsManager.getInstance();
      if (usersLoading.success)
        notificationsManager.pushNewNotification("Caricamento utenti completato", "", NotificationType.Ok);
      else
        notificationsManager.pushNewNotification(
          "Caricamento utenti fallito",
          usersLoading.errorMsg,
          NotificationType.Ko
        );
      loginPLOC.tryLoadLoggedUser();
    });

    return {
      appState,
      getCalendarPageId,
      getAnalysisPageId,
      getUsersPageId,
      getPerformancesPageId,
      usersSate,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.internal-app {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }

  .list-macro-btn {
    color: $color-primary;
    margin-right: 5px;
    background: $gray-100;
    border: 1px solid $gray-300;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    padding: 5px;

    &:hover {
      background: $gray-300;
    }
  }

  .list-header {
    .list-section-macro-part {
      width: 100%;
      font-size: 12px;

      @media only screen and (min-width: 950px) {
        font-size: 16px;
      }

      .fixed-part {
        padding: 0px 5px 5px 5px;
        border-bottom: 1px solid $gray-200;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .macro-btns {
          display: flex;
        }

        .fixed-right-part {
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          align-items: center;

          .separator {
            margin-right: 5px;
            margin-left: 3px;
          }

          .list-len-container {
            .list-len {
              font-weight: 600;
            }
          }
          .plus-icon {
            font-size: 20px;

            cursor: pointer;
            &:hover {
              color: $selected-color;
            }
          }
        }
      }

      .selection-part {
        padding: 5px 10px 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid $gray-200;

        .selection-number {
          font-weight: 600;
          margin-right: 5px;
        }

        .delete-selected {
          padding: 10px;
          font-size: 18px;

          &:hover {
            color: white;
          }
        }
      }
    }
  }

  .filter-values-container {
    .filter-value-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .filter-value-item {
        padding-left: 35px;

        .checkmark {
          top: 4px;
        }
      }

      .only {
        margin-right: 20px;
        display: none;
        align-items: center;
      }

      &:hover {
        .only {
          display: block;
          cursor: pointer;
          color: $selected-color;
        }
      }
    }
  }

  .custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 2;
    margin-left: 5px;

    &:hover {
      input {
        ~ .checkmark {
          background-color: #ccc;
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ .checkmark {
          background-color: $selected-color;

          &:after {
            display: block;
          }
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border-radius: 2px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 4px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    label,
    input {
      cursor: pointer;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.enabled {
  cursor: pointer;
}

.internal-app-content {
  height: 100%;
  width: 100%;

  .modal {
    background: white;
    @media only screen and (min-width: 550px) {
      background-color: rgba(0, 0, 0, 0.4);
    }
    .modal-content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @media only screen and (min-width: 550px) {
        width: 580px;
        border-radius: 7px;
        height: auto;
      }

      .modal-close {
        display: none;

        @media only screen and (min-width: 550px) {
          display: flex;
          justify-content: flex-end;
        }
      }

      .modal-header {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $gray-300;
        align-items: center;

        h3 {
          font-weight: 500;
        }

        .title-icon {
          font-size: 24px;
          margin-right: 10px;
        }
      }

      .modal-body {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.875rem;
        height: 100%;
        .modal-field {
          display: flex;
          flex-direction: column;

          .modal-field-label {
            margin-bottom: 10px;
            font-weight: 500;
          }

          .modal-field-value {
            display: flex;
            flex-direction: column;
          }

          &:not(:first-child) {
            padding-top: 1vh;
          }

          .txt-input {
            height: 30px;
            border: 1px solid $gray-300;
            border-radius: 3px;
            width: 350px;
            padding: 10px;
            &:focus {
              outline: 0;
            }
          }

          @media only screen and (min-width: 550px) {
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .modal-field-label {
              margin-bottom: 0px;
            }

            &:not(:first-child) {
              padding-top: 30px;
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        border-top: 1px solid $gray-300;
        padding-top: 10px;

        @media only screen and (min-width: 550px) {
          justify-content: flex-end;
          padding-right: 10px;
          padding-top: 20px;
        }

        .btn {
          &:not(:last-child) {
            margin-right: 3px;
          }
        }
      }
    }
  }

  .section-content {
    height: calc(100vh - $page-header-height-desktop);
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      height: calc(100svh - $iconbar-navlink-height-mobile - $page-header-height-mobile);
    }

    @media only screen and (orientation: landscape) {
      height: calc(100svh - $page-header-height-desktop);
    }
  }

  .multiselect {
    height: $fields-height;
    min-height: $fields-height;

    .multiselect__select {
      height: $fields-height;
    }
    .multiselect__tags {
      height: $fields-height;
      padding: 0px;
      min-height: $fields-height;

      .multiselect__single {
        display: inline;
        margin: 0px;
        vertical-align: unset;
        line-height: 26px;
      }

      .multiselect__placeholder {
        margin-left: 10px;
        padding-top: 4px;
      }
    }
    .multiselect__option {
      padding: 0 0 0 5px;
      display: flex;
      align-items: center;
      min-height: 30px;
      &:after {
        line-height: 30px;
      }
    }
  }
  .multiselect--active {
    .multiselect__input {
      width: 99% !important;
      padding: 5px;
    }
  }
  .status-icon-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - $page-header-height-desktop);

    @media only screen and (max-width: 600px) {
      height: calc(100vh - $iconbar-navlink-height-mobile - $page-header-height-mobile);
    }

    .warning-icon {
      color: rgba(253, 190, 15, 0.671);
      font-size: 50px;
    }
    .error-icon {
      color: red;
      font-size: 50px;
    }
    span {
      text-align: center;
    }
  }
}
</style>
