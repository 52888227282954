import { FactoryNavigationGateway } from "../FactoryNavigationGateway";
import { WebEnvGateway } from "../WebEnvGateway";
import { PageChangeResult } from "../PageChangeResult";
import { reservedPages } from "../PagesMap";

//this uc is runned on direct page change (e.g click on nav-link) or on 'passive' page change due to history navigation and hashchange
export class PageChangeUseCase {
  public static explicitPageChange(
    webEnvGW: WebEnvGateway,
    factory: FactoryNavigationGateway,
    actualCurrentPageId: string,
    newCurrentPageId: string,
    loggedIn: boolean
  ): PageChangeResult {
    const pageNavigator = factory.createPageNavigator(actualCurrentPageId, loggedIn);
    const lPageChanged = pageNavigator.changePage(newCurrentPageId);
    webEnvGW.location.hash = pageNavigator.getPageId();

    if (lPageChanged) {
      webEnvGW.localStorage.setItem("currentPage", pageNavigator.getPageId());
      webEnvGW.scrollTo(0, 0);
    }

    return {
      isPageChanged: lPageChanged,
      pageId: pageNavigator.getPageId(),
    };
  }

  public static pageChangeByHash(
    webEnvGW: WebEnvGateway,
    factory: FactoryNavigationGateway,
    actualCurrentPageId: string,
    loggedIn: boolean
  ): PageChangeResult {
    const lHashVal = webEnvGW.location.hash.replace("#", "");
    let lResult: PageChangeResult = { isPageChanged: false, pageId: "" };

    if (lHashVal && !reservedPages.includes(lHashVal))
      lResult = PageChangeUseCase.explicitPageChange(webEnvGW, factory, actualCurrentPageId, lHashVal, loggedIn);

    return lResult;
  }
}
