import { CELL_HEIGHT, COLUMN_HEAD_CELL_TYPE, COLUMN_WIDTH, VALUES_SEPARATOR } from "../../common/PivotConsts";
import { getDescFromCode, valueIsValid } from "../../common/PivotUtils";
import { IPivotChunkIndex } from "../../domain/PivotDS";
import CellWidthCalculator from "./CellWidthCalculator";
import { DomDrawState } from "../common/DomDrawState";
import { IPivotOptions } from "../../domain/PivotOptionsDS";

interface ITotalInfo {
  isTotalColumn: boolean;
  isSubTotalColumn: boolean | 0;
}

interface IColHeadCell {
  cssClass: string;
  textContent: string;
  pathColumn: string;
  isMeasure: number | boolean;
  idx: number;
}

interface IDrawContext {
  groupFirstColumn: Array<string>;
  columnUnique: Array<string>;
  isFirstColumnOfGroup: boolean;
  relativeColumn: number;
  pivotIndex: IPivotChunkIndex;
  totalInfo: ITotalInfo;
  opts: IPivotOptions;
  isParentOpen: boolean;
  jColumnAbs: number;
  keyContainsMeasure: number;
  nDataColumnsDOM: number;
  firstColumnAbs: number;
}

export default class PivotColHeadData {
  private pivotColHeadDataDOM: HTMLElement;
  private columnsHeadCells: Map<string, HTMLElement>;
  private cellWidthCalculator: CellWidthCalculator;

  constructor(pivotColHeadDataDOM: HTMLElement, cellWidthCalculator: CellWidthCalculator) {
    this.pivotColHeadDataDOM = pivotColHeadDataDOM;
    this.columnsHeadCells = new Map();
    this.cellWidthCalculator = cellWidthCalculator;
  }

  private buildMeasureCell(colHeadCell: IColHeadCell, drawCtx: IDrawContext) {
    colHeadCell.cssClass += " measure";
    colHeadCell.textContent = getDescFromCode(
      drawCtx.pivotIndex.columnsUniques?.[drawCtx.relativeColumn]?.slice(-1)?.[0],
      drawCtx.pivotIndex.codeToDesc
    );
  }

  private buildTotalColumnCell(colHeadCell: IColHeadCell, drawCtx: IDrawContext) {
    if (
      (drawCtx.groupFirstColumn.length !== drawCtx.columnUnique.length ||
        drawCtx.pivotIndex.maxOpenedFieldsOnColumn === 1) &&
      colHeadCell.idx >= drawCtx.columnUnique.length
    ) {
      drawCtx.groupFirstColumn = drawCtx.columnUnique;
      drawCtx.isFirstColumnOfGroup = true;
    }

    if (drawCtx.isFirstColumnOfGroup) {
      if (colHeadCell.idx === drawCtx.columnUnique.length) {
        if (drawCtx.totalInfo.isTotalColumn) {
          colHeadCell.textContent = "Totale";
        } else {
          colHeadCell.textContent = `${getDescFromCode(
            drawCtx.columnUnique.slice(-1)?.[0],
            drawCtx.pivotIndex.codeToDesc,
            drawCtx.opts.columns[drawCtx.columnUnique.length - 1].alias
          )} [${"Totale"}]`;
        }
      }
    }
  }

  private buildGenericColumnCell(colHeadCell: IColHeadCell, drawCtx: IDrawContext) {
    if (
      drawCtx.isFirstColumnOfGroup ||
      (drawCtx.groupFirstColumn[colHeadCell.idx] !== drawCtx.columnUnique[colHeadCell.idx] &&
        valueIsValid(drawCtx.columnUnique[colHeadCell.idx]))
    ) {
      drawCtx.isFirstColumnOfGroup = true;
      drawCtx.groupFirstColumn.splice(
        colHeadCell.idx,
        drawCtx.pivotIndex.maxOpenedFieldsOnColumn,
        drawCtx.columnUnique[colHeadCell.idx]
      );
      if (colHeadCell.idx < drawCtx.opts.columns.length - 1) {
        colHeadCell.pathColumn = drawCtx.columnUnique.slice(0, colHeadCell.idx + 1).join(VALUES_SEPARATOR);
        let isOpen = false;
        if (drawCtx.isParentOpen) {
          isOpen = drawCtx.pivotIndex.openedColumns.has(colHeadCell.pathColumn);
          drawCtx.isParentOpen = isOpen;
          colHeadCell.cssClass += isOpen ? " open" : " close";
        }
      }
      colHeadCell.textContent = getDescFromCode(
        drawCtx.columnUnique?.[colHeadCell.idx],
        drawCtx.pivotIndex.codeToDesc,
        drawCtx.opts.columns[colHeadCell.idx].alias
      );
    }
  }

  private adjustCssClass(colHeadCell: IColHeadCell, drawCtx: IDrawContext) {
    if (drawCtx.jColumnAbs === drawCtx.pivotIndex?.allColumnsLength - 1) {
      colHeadCell.cssClass += " last-column-of-row";
    }
    if (!colHeadCell.isMeasure) {
      if (
        colHeadCell.idx >
          drawCtx.columnUnique.length - +!(drawCtx.totalInfo.isSubTotalColumn || drawCtx.totalInfo.isTotalColumn) &&
        colHeadCell.idx < drawCtx.pivotIndex.maxOpenedFieldsOnColumn - drawCtx.keyContainsMeasure
      ) {
        colHeadCell.cssClass += " no-division-border";
      }
      if (drawCtx.isFirstColumnOfGroup) {
        colHeadCell.cssClass += " new-cell";
      }
    }
  }

  private drawCell(columnCellDOM: HTMLElement, colHeadCell: IColHeadCell) {
    columnCellDOM.setAttribute("class", colHeadCell.cssClass);
    columnCellDOM.setAttribute("data-head-path", colHeadCell.pathColumn);
    columnCellDOM.textContent = colHeadCell.textContent;
    const contentWidth = this.cellWidthCalculator.calculateCellWidth(colHeadCell.textContent);
    if (contentWidth + CellWidthCalculator.TOLERANCE > COLUMN_WIDTH) {
      columnCellDOM.classList.add("need-tooltip");
    }
  }

  private initDraw() {
    const drawCtx = {
      relativeColumn: 0,
      columnUnique: new Array<string>(),
      totalInfo: {} as ITotalInfo,
      isFirstColumnOfGroup: false,
      groupFirstColumn: new Array<string>(),
      pivotIndex: DomDrawState.value().params.pivotIndex,
      opts: DomDrawState.value().params.opts,
      isParentOpen: false,
      keyContainsMeasure: 0,
      jColumnAbs: 0,
      nDataColumnsDOM: DomDrawState.value().dataValues.nColumnsDom,
      firstColumnAbs: DomDrawState.value().scroll.firstColumnAbsolute,
    };
    drawCtx.keyContainsMeasure = +drawCtx.opts.measuresOnColumn;
    drawCtx.groupFirstColumn =
      drawCtx.pivotIndex.columnsUniques?.[drawCtx.firstColumnAbs - 1]?.slice(
        0,
        -drawCtx.keyContainsMeasure || undefined
      ) || [];
    return drawCtx;
  }

  private initColumnDraw(j: number, drawCtx: IDrawContext) {
    drawCtx.jColumnAbs = j + drawCtx.firstColumnAbs;
    drawCtx.relativeColumn = drawCtx.jColumnAbs - DomDrawState.value().chunk.chunkStartColumnAbsolute;
    drawCtx.columnUnique = drawCtx.pivotIndex.columnsUniques?.[drawCtx.relativeColumn]?.slice(
      0,
      -drawCtx.keyContainsMeasure || undefined
    );
    drawCtx.totalInfo = this.populateTotal(drawCtx.columnUnique, drawCtx.pivotIndex, drawCtx.keyContainsMeasure);
    drawCtx.isFirstColumnOfGroup = false;
    drawCtx.isParentOpen = true;
  }

  public calculateSize(showColEntitiesMetadata: boolean) {
    const nRows = DomDrawState.value().params.pivotIndex?.maxOpenedFieldsOnColumn || 0;
    const colHeadData = DomDrawState.value().colHeadData;
    const tableSize = DomDrawState.value().tableSize;

    colHeadData.rowsChanged = nRows !== colHeadData.nRows;
    colHeadData.nRows = nRows;
    colHeadData.domHeight = (colHeadData.nRows + +showColEntitiesMetadata) * CELL_HEIGHT;
    colHeadData.fullHeight = colHeadData.domHeight;

    const halfHeight = Math.floor(tableSize.currentHeight / 2);
    const needToShrinkHeader =
      colHeadData.domHeight + DomDrawState.value().dataValues.fullHeight > tableSize.currentHeight &&
      colHeadData.domHeight > halfHeight;
    if (needToShrinkHeader) {
      colHeadData.domHeight = halfHeight;
    }
  }

  public setCSSTranslateProp(translateColumn: number) {
    this.pivotColHeadDataDOM.style.setProperty("transform", `translate(${translateColumn}px,0px)`);
  }

  public drawStructure() {
    const colHeadData = DomDrawState.value().colHeadData;
    const createColHeadCell = (iRow: number, jCol: number) => {
      const columnHeadCell = document.createElement("div");
      columnHeadCell.setAttribute("data-cell-type", COLUMN_HEAD_CELL_TYPE);
      this.columnsHeadCells.set(`${iRow}-${jCol}`, columnHeadCell);
    };
    this.columnsHeadCells.clear();
    for (let i = 0; i < colHeadData.nRows; i++) {
      for (let j = 0; j < DomDrawState.value().dataValues.nColumnsDom; j++) {
        createColHeadCell(i, j);
      }
    }
    this.pivotColHeadDataDOM.replaceChildren(...Array.from(this.columnsHeadCells.values()));
  }

  private populateTotal(columnUnique: Array<string>, pivotIndex: IPivotChunkIndex, keyContainMeasure: number) {
    const isTotalColumn = columnUnique?.length === 0;
    let isSubTotalColumn =
      columnUnique?.length && columnUnique?.length < pivotIndex.maxOpenedFieldsOnColumn - keyContainMeasure;
    let fieldIndex = 0;
    let subTotalKey = "";
    while (isSubTotalColumn && fieldIndex < columnUnique.length) {
      subTotalKey += subTotalKey ? VALUES_SEPARATOR + columnUnique[fieldIndex] : columnUnique[fieldIndex];
      isSubTotalColumn = pivotIndex.openedColumns.has(subTotalKey);
      fieldIndex++;
    }

    return { isTotalColumn, isSubTotalColumn };
  }

  public calculateColumnTemplate() {
    const nDataColumnsDOM = DomDrawState.value().dataValues.nColumnsDom;
    const firstColumnAbs = DomDrawState.value().scroll.firstColumnAbsolute;

    let columnTemplate = "";
    let prevColumnDistance = firstColumnAbs * COLUMN_WIDTH;
    for (let i = 0; i < nDataColumnsDOM; i++) {
      const currentColumnIndex = i + 1 + firstColumnAbs;
      const value = currentColumnIndex * COLUMN_WIDTH - prevColumnDistance;
      prevColumnDistance = currentColumnIndex * COLUMN_WIDTH;
      columnTemplate += ` ${value || 0}px`;
    }
    return columnTemplate;
  }

  public drawData() {
    const drawCtx = this.initDraw();
    for (let j = 0; j < drawCtx.nDataColumnsDOM; j++) {
      this.initColumnDraw(j, drawCtx);
      for (let i = 0; i < drawCtx.pivotIndex.maxOpenedFieldsOnColumn; i++) {
        const columnCellDOM = this.columnsHeadCells.get(`${i}-${j}`);
        if (columnCellDOM) {
          const colHeadCell: IColHeadCell = { cssClass: "", pathColumn: "", isMeasure: false, textContent: "", idx: i };
          if (drawCtx.columnUnique) {
            colHeadCell.cssClass += "cell cell-header col-head-cell";
            colHeadCell.isMeasure =
              drawCtx.keyContainsMeasure &&
              i === drawCtx.pivotIndex.maxOpenedFieldsOnColumn - drawCtx.keyContainsMeasure;

            if (colHeadCell.isMeasure) {
              this.buildMeasureCell(colHeadCell, drawCtx);
            } else if (drawCtx.totalInfo.isSubTotalColumn || drawCtx.totalInfo.isTotalColumn) {
              this.buildTotalColumnCell(colHeadCell, drawCtx);
            } else if (i < drawCtx.columnUnique.length) {
              this.buildGenericColumnCell(colHeadCell, drawCtx);
            }

            this.adjustCssClass(colHeadCell, drawCtx);
          }

          this.drawCell(columnCellDOM, colHeadCell);
        }
      }
    }
  }
}
