import { PLOC } from "@/core/common/presentation/PLOC";
import { PivotState, pivotInitialState } from "./PivotState";
import WorkerManager from "../worker/WorkerManager";
import PivotTable from "../draw/PivotTable";
import EntitiesHeadMetadata from "../draw/tableParts/EntitiesHeadMetadata";
import WorkerListener from "../draw/boundaries/WorkerListener";
import PivotChunk from "../draw/boundaries/PivotChunk";
import DomEventsHandler from "../draw/events/DomEventsHandler";
import {
  APPLY_OPTIONS_ACTION,
  APPLY_OPTIONS_MSG,
  CLOSE_MODAL_ACTION,
  ENTITIES_ERROR_ACTION,
  EXPORT_AS_CSV_MSG,
  FILTERS_MODAL_ID,
  INDEX_DATA_ACTION,
  SET_INDEXED_DATA_MSG,
} from "../common/PivotConsts";
import IOnMessageResponseExternal from "../worker/IOnMessageResponseExternal";
import PerformancesPLOC from "@/core/performances/presentation/PerformancesPLOC";
import UsersPLOC from "@/core/users/presentation/UsersPLOC";
import { IFilter, IPivotOptions } from "../domain/PivotOptionsDS";
import { PivotOptionsEditing } from "../domain/usecases/PivotOptionsEditing";
import PivotEntitiesUtils from "../domain/usecases/PivotEntitiesUtils";
import PivotOrdering from "../domain/usecases/PivotOrderingOptions";
import PivotFormatting from "../domain/usecases/PivotFormatting";
import PivotData from "../domain/PivotData";
import PivotFiltersEditing from "../domain/usecases/PivotFiltersEditing";
import PivotFormatter from "../domain/pivotParts/PivotFormatter";
import PivotFormatterImpl from "../common/PivotFormatterImpl";
import { PivotTooltip } from "../draw/events/PivotTooltip";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";
import { DomDrawState } from "../draw/common/DomDrawState";

export default class PivotPLOC extends PLOC<PivotState> {
  private workerManager: WorkerManager;
  private workerManagerInitialized: boolean;
  private workerListenerExternal: IOnMessageResponseExternal;
  private performancesPLOC: PerformancesPLOC;
  private usersPLOC: UsersPLOC;
  private pivotData: PivotData;
  private pivotChunk: PivotChunk;
  //instanze usata fuori dal worker (non si possono passare oggetti al worker)
  private formatter: PivotFormatter;

  constructor(performancesPLOC: PerformancesPLOC, pivotData: PivotData, usersPLOC: UsersPLOC) {
    super(pivotInitialState);
    this.workerManager = {} as WorkerManager;
    this.workerManagerInitialized = false;
    this.pivotChunk = {} as PivotChunk;
    this.performancesPLOC = performancesPLOC;
    this.usersPLOC = usersPLOC;
    this.pivotData = pivotData;
    this.formatter = {} as PivotFormatter;

    this.workerListenerExternal = {
      onSetIndexedData: () => {
        this.pivotChunk.resetChunk();
        if (this.workerManagerInitialized)
          this.workerManager.postMessage({
            type: APPLY_OPTIONS_MSG,
            props: {
              opts: this.state.opts,
              rowStart: 0,
              rowEnd: 0,
              columnStart: 0,
              columnEnd: 0,
            },
          });
      },
      onApplyOptions: () => {
        this.changeState({ ...this.state, loading: false });
        return;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onExportAsCSV: (data: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.result as string;
        downloadLink.download = "Esportazione CSV - " + new Date().toLocaleDateString() + ".csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up
        setTimeout(() => {
          URL.revokeObjectURL(downloadLink.href);
          document.body.removeChild(downloadLink);
        }, 100);
      },
      onError: () => {
        this.changeState({ ...this.state, error: true });
        return;
      },
    };
  }

  private mountPivot(pivotTableDOM: HTMLElement) {
    //Create elements
    const pivotContainerObj = new PivotTable(pivotTableDOM);

    const pivotDimnesionsDescrHead = new EntitiesHeadMetadata(
      pivotTableDOM.querySelector(".pivot-col-head-entities-metadata") || new HTMLElement(),
      pivotTableDOM.querySelector(".pivot-row-head-entities-metadata") || new HTMLElement()
    );
    const workerListenerInternal = new WorkerListener(pivotContainerObj);
    this.workerManager = new WorkerManager(this.workerListenerExternal, workerListenerInternal);
    this.pivotChunk = new PivotChunk(this.workerManager);
    const pivotTooltipDOM: HTMLElement = pivotTableDOM.querySelector(".pivot-tooltip") || new HTMLElement();
    const pivotTooltipManager = new PivotTooltip(pivotTooltipDOM);
    const domEventsManager = new DomEventsHandler(this.workerManager, pivotTooltipManager);

    //Init elements
    pivotContainerObj.init(this.state.opts, domEventsManager, this.pivotChunk, pivotDimnesionsDescrHead);
    this.workerManager.initWorker();
    this.workerManagerInitialized = true;
  }

  private async loadOptions() {
    const opts = await PivotOptionsEditing.loadOptions(this.pivotData);
    this.changeState({ ...this.state, opts });
  }

  public async setupPivot(pivotTableDOM: HTMLElement) {
    const performancesLoading = await this.performancesPLOC.loadPerformances();
    if (!performancesLoading.success)
      console.error("PivotPLOC/setupPivot/loadPerformances: cannot load performances " + performancesLoading.errorMsg);

    const usersLoading = await this.usersPLOC.loadUsers();
    if (!usersLoading.success)
      console.error("PivotPLOC/setupPivot/loadUsers: cannot load users " + usersLoading.errorMsg);

    await this.loadOptions();

    this.formatter = new PivotFormatterImpl(this.usersPLOC.getUsersMap(), this.performancesPLOC.getPerformancesMap());

    this.mountPivot(pivotTableDOM);

    return await this.indexData();
  }

  public async indexData() {
    const notificationsManager = NotificationsManager.getInstance();
    const dataIndexResponse = await this.pivotData.getIndexedPivotData(this.state.opts);
    if (dataIndexResponse.success) {
      notificationsManager.pushNewNotification("Caricamento pivot completato", "", NotificationType.Ok);
      this.workerManager.postMessage({
        type: SET_INDEXED_DATA_MSG,
        props: {
          data: dataIndexResponse.indexedData,
          usersMap: this.usersPLOC.getUsersMap(),
          performancesMap: this.performancesPLOC.getPerformancesMap(),
        },
      });
      this.changeState({ ...this.state, emptyData: dataIndexResponse.emptyData });
    } else {
      this.changeState({ ...this.state, error: true });
      notificationsManager.pushNewNotification(
        "Caricamento pivot fallito",
        "consultare i log per dettagli",
        NotificationType.Ko
      );
    }

    return dataIndexResponse;
  }

  public unMountPivot() {
    if (this.workerManagerInitialized) this.workerManager.terminate();
    this.changeState({ ...this.state, loading: true });
  }

  public openOptionsModal(modalId: string) {
    const optionsOpening = PivotOptionsEditing.openOptions(this.state.opts, modalId, this.state.loading);
    this.changeState({
      ...this.state,
      modalId,
      optsWorkingCopy: optionsOpening.optsWorkingCopy,
      availableDimensions: optionsOpening.availableDimensions,
      availableMeasures: optionsOpening.availableMeasures,
      modalVisible: optionsOpening.modalVisible,
      filtersEditing: {
        ...this.state.filtersEditing,
        filterSubtitle: optionsOpening.filterSubtitle,
      },
    });
  }

  public closeOptionsModal() {
    this.changeState({
      ...this.state,
      modalVisible: false,
      optsWorkingCopy: {} as IPivotOptions,
      filtersEditing: {
        editFilterMode: false,
        filterOnEdit: {} as IFilter,
        filterSubtitle: "",
        filterSaveErrorMsg: "",
      },
    });
  }

  public isFiltered() {
    return this.state.opts.filters.length > 0;
  }

  public async saveOptions(optionsId: string) {
    const saveResult = await PivotOptionsEditing.saveOptions(this.state.optsWorkingCopy, optionsId, this.pivotData);

    saveResult.actions.forEach((action: string) => {
      switch (action) {
        case CLOSE_MODAL_ACTION:
          if (saveResult.success)
            this.changeState({
              ...this.state,
              modalVisible: false,
              opts: saveResult.newOptions,
              optsWorkingCopy: {} as IPivotOptions,
              loading: true,
            });
          break;
        case ENTITIES_ERROR_ACTION:
          this.changeState({ ...this.state, entitiesOk: false, showPivotEntitiesMsg: true });
          break;
        case INDEX_DATA_ACTION:
          if (saveResult.success) this.indexData();
          break;
        case APPLY_OPTIONS_ACTION:
          if (saveResult.success)
            this.workerManager.postMessage({
              type: APPLY_OPTIONS_MSG,
              props: {
                opts: saveResult.newOptions,
                rowStart: DomDrawState.value().chunk.chunkStartRowAbsolute,
                rowEnd: DomDrawState.value().chunk.chunkEndRowAbsolute,
                columnStart: DomDrawState.value().chunk.chunkStartColumnAbsolute,
                columnEnd: DomDrawState.value().chunk.chunkEndColumnAbsolute,
              },
            });
          break;
      }
    });
  }

  public formatOrderBy(orderById: string) {
    return PivotOrdering.formatOrderBy(orderById, this.state.opts);
  }

  public setEntityOrderBy(orderByValue: string, entityAlias: string) {
    PivotOrdering.setEntityOrderBy(orderByValue, entityAlias, this.state.optsWorkingCopy);
    this.changeState({ ...this.state });
  }

  public getWorkingSelectedDimensions() {
    return PivotEntitiesUtils.getSelectedDimensions(this.state.optsWorkingCopy);
  }

  public getSelectedMeasuresIds() {
    return PivotEntitiesUtils.getSelectedMeasuresIds(this.state.opts);
  }

  public getEntityFormatDesc(entityFormatCode: string) {
    return PivotFormatting.getEntityFormatDesc(entityFormatCode);
  }

  public setEntityFormat(value: string, alias: string) {
    PivotFormatting.setEntityFormat(value, alias, this.state.optsWorkingCopy);
    this.changeState({ ...this.state });
  }

  public getEntityFormats(alias: string) {
    return PivotFormatting.getEntityFormats(alias);
  }

  public getWorkingFormattableDimensions() {
    return PivotFormatting.getFormattableDimensions(this.state.optsWorkingCopy);
  }

  public getFilterDescription(filter: IFilter) {
    return PivotFiltersEditing.getFilterDescription(filter, this.state.optsWorkingCopy);
  }

  public createNewFilter() {
    const newFilterResult = PivotFiltersEditing.createNewFilter();
    this.changeState({
      ...this.state,
      optsWorkingCopy: this.state.optsWorkingCopy,
      filtersEditing: {
        ...this.state.filtersEditing,
        ...newFilterResult,
      },
    });
  }

  public getSelectedDimensionsIds() {
    return PivotEntitiesUtils.getSelectedDimensionsIds(this.state.opts);
  }

  public getDimensionDescription(dimAlias: string) {
    return PivotEntitiesUtils.getDimensionDescription(dimAlias);
  }

  public changeFilterOnEditEntity(entity: string) {
    PivotFiltersEditing.changeFilterEntity(
      entity,
      this.state.filtersEditing.filterOnEdit,
      this.usersPLOC,
      this.performancesPLOC,
      this.pivotData
    );
    this.changeState({
      ...this.state,
      filtersEditing: {
        ...this.state.filtersEditing,
        filterOnEdit: JSON.parse(JSON.stringify(this.state.filtersEditing.filterOnEdit)),
      },
    });
  }

  public exitEditFilterMode() {
    const exitResult = PivotFiltersEditing.exitEditFilterMode();
    this.changeState({
      ...this.state,
      modalVisible: exitResult.modalVisible,
      filtersEditing: {
        editFilterMode: exitResult.editFilterMode,
        filterOnEdit: exitResult.filterOnEdit,
        filterSubtitle: exitResult.filterSubtitle,
        filterSaveErrorMsg: exitResult.filterSaveErrorMsg,
      },
    });
  }

  public getListFilterValue(index: number) {
    return PivotFiltersEditing.getListFilterValue(
      index,
      this.state.filtersEditing.filterOnEdit,
      this.formatter,
      this.state.optsWorkingCopy
    );
  }

  public toggleFilterSelectedValue(value: string) {
    const filterSaveErrorMsg =
      PivotFiltersEditing.toggleFilterSelectedValue(this.state.filtersEditing.filterOnEdit, value) || "";
    this.changeState({ ...this.state, filtersEditing: { ...this.state.filtersEditing, filterSaveErrorMsg } });
  }

  public getFilterableDims() {
    return PivotFiltersEditing.getFilterableDims(this.state.optsWorkingCopy);
  }

  public onFiltersClose() {
    const wasOnEdit = this.state.filtersEditing.editFilterMode;
    const closeResult = PivotFiltersEditing.onClose(this.state.filtersEditing.editFilterMode);
    this.changeState({
      ...this.state,
      filtersEditing: {
        editFilterMode: closeResult.editFilterMode,
        filterOnEdit: closeResult.filterOnEdit,
        filterSaveErrorMsg: closeResult.filterSaveErrorMsg,
        filterSubtitle: closeResult.filterSubtitle,
      },
      modalVisible: closeResult.modalVisible,
    });
    if (!wasOnEdit) this.closeOptionsModal();
  }

  public onFiltersSave() {
    const wasOnEdit = this.state.filtersEditing.editFilterMode;
    const saveResult = PivotFiltersEditing.onSave(
      this.state.filtersEditing.editFilterMode,
      this.state.filtersEditing.filterOnEdit,
      this.pivotData,
      this.state.optsWorkingCopy.filters
    );
    this.changeState({
      ...this.state,
      modalVisible: saveResult.modalVisible,
      filtersEditing: {
        ...this.state.filtersEditing,
        editFilterMode: saveResult.editFilterMode,
        filterOnEdit: saveResult.filterOnEdit,
        filterSaveErrorMsg: saveResult.filterSaveErrorMsg,
      },
    });
    if (!wasOnEdit) this.saveOptions(FILTERS_MODAL_ID);
  }

  doExportCSV() {
    if (!this.state.loading && !this.state.error)
      this.workerManager.postMessage({
        type: EXPORT_AS_CSV_MSG,
      });
  }

  public getTemporalFilterValue() {
    return PivotFiltersEditing.getTemporalFilterValue(this.state.filtersEditing.filterOnEdit);
  }

  public setTemporalFilterValue(value: Date, isStart: boolean) {
    const filterSaveErrorMsg = PivotFiltersEditing.setTemporalFilterValue(
      this.state.filtersEditing.filterOnEdit,
      value,
      isStart
    );
    this.changeState({ ...this.state, filtersEditing: { ...this.state.filtersEditing, filterSaveErrorMsg } });
  }

  public isNewFilter() {
    return PivotFiltersEditing.isNewFilter(this.state.optsWorkingCopy.filters, this.state.filtersEditing.filterOnEdit);
  }

  public editFilter(entityAlias: string) {
    const filterOnEdit = <IFilter>(
      this.state.optsWorkingCopy.filters.find((filter) => filter.entityAlias === entityAlias)
    );
    const result = PivotFiltersEditing.editFilter(filterOnEdit, this.pivotData, this.usersPLOC, this.performancesPLOC);
    this.changeState({ ...this.state, filtersEditing: { ...this.state.filtersEditing, ...result } });
  }

  public removeFilter(entityAlias: string) {
    const newFilters = PivotFiltersEditing.removeFilter(entityAlias, this.state.optsWorkingCopy.filters);
    this.changeState({ ...this.state, optsWorkingCopy: { ...this.state.optsWorkingCopy, filters: newFilters } });
  }

  public selectAllFilterValues() {
    PivotFiltersEditing.selectAll(this.state.filtersEditing.filterOnEdit);
  }
  public selectOneFilterValue(value: string) {
    PivotFiltersEditing.selectOne(this.state.filtersEditing.filterOnEdit, value);
  }
  public selectNoneFilterValues() {
    const result = PivotFiltersEditing.selectNone(this.state.filtersEditing.filterOnEdit);
    this.changeState({ ...this.state, ...result });
  }
}
