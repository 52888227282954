import {
  CUSTOMER_DIM_CODE,
  DATE_DIM_CODE,
  EMPLOYEES_DIM_CODE,
  FULL_USER_FORMAT,
  INITIALS_USER_FORMAT,
  LONG_DATE_FORMAT,
  LONG_MONTH_DATE_FORMAT,
  LONG_MONTH_YEAR_DATE_FORMAT,
  MONTH_2DIGIT_YEAR_DATE_FORMAT,
  ONLY_NAME_USER_FORMAT,
  ONLY_SURNAME_USER_FORMAT,
  PERFORMANCE_DIM_CODE,
  SHORT_MONTH_DATE_FORMAT,
  SHORT_MONTH_YEAR_DATE_FORMAT,
} from "../../common/PivotConsts";
import { DATE_FORMATS, USERS_FORMATS } from "../pivotParts/PivotFormatter";
import { IEntity, IPivotOptions } from "../PivotOptionsDS";
import PivotEntitiesUtils from "./PivotEntitiesUtils";

export default class PivotFormatting {
  public static getEntityFormatDesc(entityFormatCode: string) {
    switch (entityFormatCode) {
      default:
        return "Formato non trovato";
      case FULL_USER_FORMAT:
        return "Nome e cognome";
      case ONLY_NAME_USER_FORMAT:
        return "Solo nome";
      case ONLY_SURNAME_USER_FORMAT:
        return "Solo cognome";
      case INITIALS_USER_FORMAT:
        return "Iniziali";
      case LONG_DATE_FORMAT:
        return "gg/mm/aaaa";
      case SHORT_MONTH_DATE_FORMAT:
        return "Mese breve (es. 11 ago 2023)";
      case LONG_MONTH_DATE_FORMAT:
        return "Mese esteso (es. 11 agosto 2023)";
      case MONTH_2DIGIT_YEAR_DATE_FORMAT:
        return "Mese, anno (es. 08 - 2023)";
      case SHORT_MONTH_YEAR_DATE_FORMAT:
        return "Mese breve, anno (es. ago - 2023)";
      case LONG_MONTH_YEAR_DATE_FORMAT:
        return "Mese esteso, anno (es. agosto - 2023)";
    }
  }

  public static setEntityFormat(value: string, alias: string, opts: IPivotOptions) {
    const dimension = PivotEntitiesUtils.getSelectedDimensions(opts).find((dim: IEntity) => {
      return dim.alias === alias;
    });
    if (dimension) dimension.format = value;
    else console.warn("PivotFormatting/setEntityFormat:entity " + alias + " not found");
  }

  public static getEntityFormats(alias: string) {
    switch (alias) {
      case EMPLOYEES_DIM_CODE:
      case CUSTOMER_DIM_CODE:
        return USERS_FORMATS;
      case DATE_DIM_CODE:
        return DATE_FORMATS;
      default:
        console.warn("PivotFormatting/getEntityFormats:entity " + alias + " not found");
        return [];
    }
  }

  public static getFormattableDimensions(opts: IPivotOptions) {
    const result = new Array<IEntity>();
    PivotEntitiesUtils.getSelectedDimensions(opts).forEach((dim) => {
      if (dim.alias !== PERFORMANCE_DIM_CODE) result.push(dim);
    });
    return result;
  }
}
