import { CELL_HEIGHT } from "../common/PivotConsts";
import { IPivotChunkIndex } from "../domain/PivotDS";
import { IPivotOptions } from "../domain/PivotOptionsDS";
import CellWidthCalculator from "./tableParts/CellWidthCalculator";
import PivotChunk from "./boundaries/PivotChunk";
import { DomDrawState } from "./common/DomDrawState";
import DomEventsHandler from "./events/DomEventsHandler";
import EntitiesHeadMetadata from "./tableParts/EntitiesHeadMetadata";
import IDrawPivot from "./tableParts/IDrawPivot";
import PivotColHeadData from "./tableParts/PivotColHeadData";
import PivotDataValues from "./tableParts/PivotDataValues";
import PivotRowHeadData from "./tableParts/PivotRowHeadData";
import PivotScroll from "./tableParts/PivotScroll";
import PivotTableSize from "./tableParts/PivotTableSize";

export default class PivotTable extends IDrawPivot {
  private pivotTableDOM: HTMLElement;
  private entitiesHeadMetadata: EntitiesHeadMetadata;
  private pivotRowHeadData: PivotRowHeadData;
  private pivotViewportDOM: HTMLElement;
  private pivotDataValuesDOM: HTMLElement;
  private pivotColHeadDataDOM: HTMLElement;
  private pivotScrollbarDOM: HTMLElement;
  private pivotRowHeadVScrollDOM: HTMLElement;
  private pivotColHeadHScrollDOM: HTMLElement;
  private pivotScrolling: PivotScroll;
  private pivotDataValues: PivotDataValues;
  private pivotColHeadData: PivotColHeadData;
  private pivotChunk: PivotChunk;
  private domEventsHandler: DomEventsHandler;
  private cellWidthCalculator: CellWidthCalculator;
  private tableSize: PivotTableSize;

  constructor(pivotTableDOM: HTMLElement) {
    super();
    this.pivotTableDOM = pivotTableDOM;
    this.entitiesHeadMetadata = {} as EntitiesHeadMetadata;
    this.pivotViewportDOM = this.pivotTableDOM.querySelector(".pivot-viewport") || new HTMLElement();
    this.pivotDataValuesDOM = this.pivotTableDOM.querySelector(".pivot-data-values") || new HTMLElement();
    this.pivotColHeadDataDOM = this.pivotTableDOM.querySelector(".pivot-col-head-data") || new HTMLElement();
    this.pivotScrollbarDOM = this.pivotTableDOM.querySelector(".pivot-scrollbar-box") || new HTMLElement();
    this.pivotRowHeadVScrollDOM =
      this.pivotTableDOM.querySelector(".pivot-row-head-vscroll-hidden") || new HTMLElement();
    this.pivotColHeadHScrollDOM =
      this.pivotTableDOM.querySelector(".pivot-col-head-hscroll-hidden") || new HTMLElement();
    const cellWidthCalculatorDOM: HTMLElement =
      this.pivotTableDOM.querySelector("#cellWidthCalculator") || new HTMLElement();
    this.cellWidthCalculator = new CellWidthCalculator(cellWidthCalculatorDOM, "600 14px Montserrat, sans-serif");
    this.pivotScrolling = new PivotScroll(
      this.pivotScrollbarDOM,
      this.pivotRowHeadVScrollDOM,
      this.pivotColHeadHScrollDOM,
      this
    );
    this.pivotDataValues = new PivotDataValues(this.pivotDataValuesDOM);
    this.pivotColHeadData = new PivotColHeadData(this.pivotColHeadDataDOM, this.cellWidthCalculator);
    this.pivotRowHeadData = new PivotRowHeadData(
      this.pivotTableDOM.querySelector(".pivot-row-head-data") || new HTMLElement(),
      this.cellWidthCalculator
    );
    this.pivotChunk = {} as PivotChunk;
    this.domEventsHandler = {} as DomEventsHandler;
    this.tableSize = new PivotTableSize(this, this.pivotTableDOM);
    DomDrawState.getInstance().reset();
  }

  public init(
    opts: IPivotOptions,
    clickManager: DomEventsHandler,
    chunkManager: PivotChunk,
    entitiesHeadMetadata: EntitiesHeadMetadata
  ) {
    DomDrawState.value().params = {
      pivotIndex: {} as IPivotChunkIndex,
      dataChanged: false,
      opts,
    };
    this.pivotChunk = chunkManager;
    this.domEventsHandler = clickManager;
    this.entitiesHeadMetadata = entitiesHeadMetadata;
    this.tableSize.doObserve();
    this.pivotScrolling.attachScrollEvents();
    this.attachMouseEvents();
  }

  attachMouseEvents() {
    this.pivotTableDOM.addEventListener(
      "click",
      (e) => {
        this.domEventsHandler.handleClick(e);
      },
      {
        capture: true,
      }
    );

    this.pivotTableDOM.addEventListener(
      "mouseover",
      (e) => {
        this.domEventsHandler.handleMouseOver(e);
      },
      {
        capture: true,
      }
    );
  }

  private canDraw() {
    return DomDrawState.value().tableSize.hasSpaceForDraw && DomDrawState.value().params.pivotIndex?.valuesTreeMap;
  }

  private DOMSizingChanged() {
    return (
      DomDrawState.value().dataValues.columnsChanged ||
      DomDrawState.value().dataValues.rowsChanged ||
      DomDrawState.value().colHeadData.rowsChanged ||
      DomDrawState.value().rowHeadData.columnsChanged
    );
  }

  private calculateSizings(showColEntitiesMetadata: boolean) {
    this.pivotDataValues.calculateFullSizing();

    this.pivotColHeadData.calculateSize(showColEntitiesMetadata);

    this.pivotRowHeadData.calculateWidth();

    this.pivotDataValues.calculateDOMSizing();

    this.pivotDataValues.calculateTotalsPositioning();
  }

  private setCSSSizingProps() {
    this.pivotTableDOM.style.setProperty("--row-head-width", `${DomDrawState.value().rowHeadData.domWidth}px`);
    this.pivotTableDOM.style.setProperty("--row-head-start-height", `${DomDrawState.value().colHeadData.domHeight}px`);
    this.pivotViewportDOM.style.setProperty("width", `${DomDrawState.value().tableSize.currentWidth}px`);
    this.pivotViewportDOM.style.setProperty("height", `${DomDrawState.value().tableSize.currentHeight}px`);
  }

  private setCSSAllDataProps(showColEntitiesMetadata: boolean) {
    this.pivotTableDOM.style.setProperty(
      "--all-data-rows-number",
      DomDrawState.value().dataValues.nAllDataRows.toString()
    );
    this.pivotTableDOM.style.setProperty(
      "--column-total-n",
      DomDrawState.value().dataValues.nAllDataColumns.toString()
    );
    this.pivotTableDOM.style.setProperty("--cell-height", `${CELL_HEIGHT}px`);
    this.pivotTableDOM.style.setProperty("--all-data-cols-width", `${DomDrawState.value().dataValues.fullWidth}px`);
    this.pivotTableDOM.style.setProperty("--row-head-template-cols", DomDrawState.value().rowHeadData.cssGridTemplate);
    this.pivotTableDOM.style.setProperty("--showHeaderColumnDimensionName", (+showColEntitiesMetadata).toString());
    this.pivotTableDOM.style.setProperty("--row-head-full-width", `${DomDrawState.value().rowHeadData.fullWidth}px`);
    this.pivotTableDOM.style.setProperty(
      "--columns-head-full-height",
      `${DomDrawState.value().colHeadData.fullHeight}px`
    );
  }

  private setCSSDomDataProps() {
    this.pivotTableDOM.style.setProperty(
      "--visible-data-rows-number",
      DomDrawState.value().dataValues.nRowsDom.toString()
    );
    this.pivotTableDOM.style.setProperty("--column-n", DomDrawState.value().dataValues.nColumnsDom.toString());
    this.pivotTableDOM.style.setProperty("--column-head-n", DomDrawState.value().rowHeadData.nColumns.toString());
    this.pivotTableDOM.style.setProperty(
      "--col-head-data-rows-number",
      DomDrawState.value().colHeadData.nRows.toString()
    );
  }

  private drawPivotStructure() {
    if (this.canDraw()) {
      if (DomDrawState.value().tableSize.sizeChanged || DomDrawState.value().params.dataChanged) {
        const showColEntitiesMetadata = this.entitiesHeadMetadata.showColEntitiesMetadata();

        this.calculateSizings(showColEntitiesMetadata);

        this.setCSSSizingProps();

        if (DomDrawState.value().params.dataChanged) {
          this.setCSSAllDataProps(showColEntitiesMetadata);

          this.entitiesHeadMetadata.draw();
        }

        if (this.DOMSizingChanged()) {
          this.setCSSDomDataProps();

          this.pivotChunk.init();

          this.pivotDataValues.drawStructure();

          this.pivotColHeadData.drawStructure();

          this.pivotRowHeadData.drawStructure();
        }
      }
      this.pivotViewportDOM.style.setProperty("display", "block");
    } else {
      this.pivotViewportDOM.style.setProperty("display", "none");
    }
  }

  private setCSSTranslateProps() {
    this.pivotDataValues.setCSSTranslateProp(
      DomDrawState.value().scroll.translateRow,
      DomDrawState.value().scroll.translateColumn
    );
    this.pivotColHeadData.setCSSTranslateProp(DomDrawState.value().scroll.translateColumn);
    this.pivotRowHeadData.setCSSTranslateProp(DomDrawState.value().scroll.translateRow);
  }

  public drawPivotData() {
    if (DomDrawState.value().params.pivotIndex?.valuesTreeMap) {
      this.pivotScrolling.calculateScrollInfo();
      this.setCSSTranslateProps();

      if (
        DomDrawState.value().scroll.isNewRow ||
        DomDrawState.value().scroll.isNewColumn ||
        DomDrawState.value().params.dataChanged ||
        DomDrawState.value().tableSize.sizeChanged
      ) {
        this.pivotChunk.calculateChunk();

        this.pivotDataValues.drawData();

        if (
          DomDrawState.value().scroll.isNewRow ||
          DomDrawState.value().params.dataChanged ||
          DomDrawState.value().tableSize.sizeChanged
        ) {
          this.pivotRowHeadData.drawData();
        }

        if (
          DomDrawState.value().scroll.isNewColumn ||
          DomDrawState.value().params.dataChanged ||
          DomDrawState.value().tableSize.sizeChanged
        ) {
          const columnTemplate = this.pivotColHeadData.calculateColumnTemplate();
          this.pivotTableDOM.style.setProperty("--col-head-template-cols", columnTemplate);

          this.pivotColHeadData.drawData();
        }
      }
    }

    this.pivotChunk.getPivotChunk();

    if (DomDrawState.value().scroll.dataScrollAnimationIsRunning) {
      window.requestAnimationFrame(this.drawPivotData.bind(this));
    }
  }

  draw() {
    this.drawPivotStructure();
    if (this.canDraw() && (DomDrawState.value().tableSize.sizeChanged || DomDrawState.value().params.dataChanged)) {
      this.drawPivotData();

      DomDrawState.value().params.dataChanged = false;
      DomDrawState.value().tableSize.sizeChanged = false;
    }
  }
}
