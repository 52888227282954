export interface LoginState {
  mail: string;
  password: string;
  loginOk: boolean;
  loginErrorMsg: string;
  loggedUserId: string;
  loginTried: boolean;
}

export const loginInitialState: LoginState = {
  mail: "",
  password: "",
  loginOk: false,
  loginErrorMsg: "",
  loggedUserId: "",
  loginTried: false,
};
