<template>
  <div class="signin-wrapper">
    <div class="card-signin">
      <logo></logo>
      <div class="signin-header">
        <h2>Benvenuto!</h2>
        <h4>Esegui il login per continuare</h4>
        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control-large" placeholder="Inserisci la tua mail" v-model="loginState.mail" />
          <div class="error" v-show="loginState.loginTried && !mailOk">
            {{ getInvalidMailMessage() }}
          </div>
        </div>
        <div class="form-group">
          <label>Password</label>
          <input
            type="password"
            class="form-control-large"
            placeholder="Inserisci la tua password"
            v-model="loginState.password"
          />
        </div>

        <div class="error" v-show="loginState.loginTried && !loginState.loginOk">
          {{ loginState.loginErrorMsg }}
        </div>
        <input type="button" value="Login" @click="doLogin()" class="btn btn-secondary" />
        <Recaptcha ref="recaptcha"></Recaptcha>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, computed, ref, nextTick } from "vue";
import { manageSubscription } from "../common/SubscriptionManager";
import { LOGIN_PLOC_KEY } from "../common/GUIConsts";
import { INVALID_MAIL } from "../../core/common/utils/ResourceStrings";
import Recaptcha from "./Recaptcha.vue";
import { User } from "@/core/users/domain/User";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";
import Logo from "../internalApp/controls/Logo.vue";

export default defineComponent({
  components: { Recaptcha, Logo },
  setup() {
    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const loginState = manageSubscription(loginPLOC);
    const recaptcha = ref<InstanceType<typeof Recaptcha> | null>(null);

    const doLogin = async function () {
      if (recaptcha.value) {
        recaptcha.value.execute();
      }
    };

    const mailOk = computed(() => {
      return User.checkMail(loginState.value.mail);
    });

    const getInvalidMailMessage = function () {
      return INVALID_MAIL;
    };

    return { loginState, doLogin, mailOk, getInvalidMailMessage, recaptcha };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media only screen and (min-width: 500px) {
    background-image: url("../../../public/bg.webp");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.card-signin {
  display: flex;
  flex-direction: column;
  background-color: rgba(#fff, 1);
  align-items: center;

  @media (min-width: 480px) {
    border: 1px solid $gray-300;
    padding: 30px 35px 50px;
  }

  .logo {
    .logo-img {
      height: 128px;
      width: 128px;
      margin-right: 10px;
    }
    .app-name {
      font-size: 40px;
    }
    .slogan {
      font-size: 17px;
    }
  }

  .signin-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0px;

    @media (min-width: 480px) {
      margin-top: 30px;
    }
    h2 {
      font-weight: 500;
      color: $color-primary;
      letter-spacing: -1px;
      margin-bottom: 0px;
      @media (min-width: 480px) {
        margin-bottom: 5px;
      }
    }

    h4 {
      margin-top: 0px;
      font-weight: 400;
      color: $gray-900;
      margin-bottom: 10px;
      text-align: left;

      @media (min-width: 480px) {
        margin-bottom: 25px;
      }
    }

    label {
      color: $gray-600;
      margin-bottom: 5px;
      font-size: 0.8em;
    }
    .btn {
      margin-top: 25px;
    }

    .form-group {
      .error {
        margin-top: 10px;
      }
    }
  }
}
</style>
