import { ROW_HEAD_CELL_TYPE } from "../../common/PivotConsts";
import { IEntity, IPivotOptions } from "../../domain/PivotOptionsDS";
import { DomDrawState } from "../common/DomDrawState";

export default class EntitiesHeadMetadata {
  private colEntitiesMetadataDOM: HTMLElement;
  private rowEntitiesMetadataDOM: HTMLElement;

  constructor(colEntitiesMetadataDOM: HTMLElement, rowEntitiesMetadataDOM: HTMLElement) {
    this.colEntitiesMetadataDOM = colEntitiesMetadataDOM;
    this.rowEntitiesMetadataDOM = rowEntitiesMetadataDOM;
  }

  private drawColumnMetadata(columns: Array<IEntity>) {
    const getDimsDescr = (columns: IEntity[] | undefined): string => {
      return (columns || []).map((currentDim: IEntity) => currentDim.desc || "").join(" | ");
    };

    const dimsDescr = getDimsDescr(columns);
    this.colEntitiesMetadataDOM.textContent = dimsDescr;
  }

  private drawRowMetadata(opts: IPivotOptions, nColumnsOnRowHeader: number) {
    const createDimDescCell = (content: string, cellType = ROW_HEAD_CELL_TYPE, cellClass = "cell cell-header") => {
      const cell = document.createElement("div");
      cell.setAttribute("data-cell-type", cellType);
      cell.setAttribute("class", cellClass);
      cell.textContent = content;
      return cell;
    };

    const dimsDescrCells = Array.from({ length: nColumnsOnRowHeader }, (_, j) => {
      const content = !opts.measuresOnColumn && j === nColumnsOnRowHeader - 1 ? "Misure" : opts.rows?.[j]?.desc || "";
      return createDimDescCell(content);
    });

    this.rowEntitiesMetadataDOM.replaceChildren(...dimsDescrCells);
  }

  public draw() {
    this.drawColumnMetadata(DomDrawState.value().params.opts.columns);

    this.drawRowMetadata(DomDrawState.value().params.opts, DomDrawState.value().rowHeadData.nColumns);
  }

  public showColEntitiesMetadata() {
    return (
      (DomDrawState.value().params.opts?.columns || []).length !== 0 ||
      !DomDrawState.value().params.opts.measuresOnColumn
    );
  }
}
