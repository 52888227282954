import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vue_recaptcha, {
      sitekey: "6LfbCvwpAAAAAP2fril8tAEwiv_MHVXzXxsDRfbc",
      size: "invisible",
      theme: "light",
      onVerify: _ctx.recaptchaVerified,
      onExpire: _ctx.recaptchaExpired,
      ref: "vueRecaptcha"
    }, null, 8, ["onVerify", "onExpire"])
  ]))
}