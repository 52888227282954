export const VALUES_SEPARATOR = "||";
export const COLUMN_WIDTH = 200;
export const CELL_HEIGHT = 30;
export const CHUNK_SIZE_FACTOR = 6;
export const CONTAINER_RESIZE_THRESHOLD = 10;
export const GET_PIVOT_CHUNK_MSG = "getPivotChunk";
export const SET_INDEXED_DATA_MSG = "setIndexedData";
export const APPLY_OPTIONS_MSG = "applyOptions";
export const TOGGLE_CELL_OPENING_MSG = "toggleCellOpening";
export const EXPORT_AS_CSV_MSG = "exportAsCsv";
export const ERROR_MSG = "error";
export const CODE_TO_DESCR_SEPARATOR = "-~-";
export const ORDER_BY_DIM_DESCR_SUFFIX = "_DESCR";
export const ORDER_TYPE_ASC = "asc";
export const LONG_DATE_FORMAT = "L";
export const SHORT_MONTH_DATE_FORMAT = "ll";
export const LONG_MONTH_DATE_FORMAT = "LL";
export const MONTH_YEAR_DATE_FORMAT = "M - YYYY";
export const MONTH_2DIGIT_YEAR_DATE_FORMAT = "MM - YYYY";
export const SHORT_MONTH_YEAR_DATE_FORMAT = "MMM - YYYY";
export const LONG_MONTH_YEAR_DATE_FORMAT = "MMMM - YYYY";
export const FULL_USER_FORMAT = "full";
export const ONLY_NAME_USER_FORMAT = "name";
export const ONLY_SURNAME_USER_FORMAT = "surname";
export const INITIALS_USER_FORMAT = "initials";
export const TRUNCATED_SURNAME_USER_FORMAT = "trunc_surname";
export const EMPLOYEES_ID_SEPARATOR = "-$E-";
export const EMPLOYEES_DIM_CODE = "D_EMPLOYEES";
export const CUSTOMER_DIM_CODE = "D_CUSTOMER";
export const DATE_DIM_CODE = "D_DATE";
export const PERFORMANCE_DIM_CODE = "D_PERFORMANCE";
export const COST_MEAS_CODE = "M_COST";
export const CSV_FIELDS_SEPARATOR = ";";
export const CSV_ROW_SEPARATOR = "\n";
export const CSV_CODE_SUFFIX = "_code";
export const CSV_DESCR_SUFFIX = "_descr";
export const SETTINGS_MODAL_ID = "settings";
export const ENTITIES_MODAL_ID = "entities";
export const ORDERING_MODAL_ID = "ordering";
export const FORMATS_MODAL_ID = "formats";
export const FILTERS_MODAL_ID = "filters";
export const CLOSE_MODAL_ACTION = "closeModal";
export const APPLY_OPTIONS_ACTION = "applyOptions";
export const INDEX_DATA_ACTION = "indexData";
export const ENTITIES_ERROR_ACTION = "entitiesError";
export const ROWS_LIST_ID = "rows";
export const COLUMNS_LIST_ID = "columns";
export const SELECTED_MEASURES_LIST_ID = "selectedMeasures";
export const AVAILABLE_DIMENSIONS_LIST_ID = "availableDimensions";
export const AVAILABLE_MEASURES_LIST_ID = "availableMeasures";
export const DIM_LIST_TYPE = "dim";
export const MEAS_LIST_TYPE = "meas";
export const ROW_HEAD_CELL_TYPE = "rowHead";
export const COLUMN_HEAD_CELL_TYPE = "columnHead";
