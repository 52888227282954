import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "app_calendar",
  class: "section-content",
  ref: "calendarElement"
}
const _hoisted_2 = {
  key: 0,
  class: "calendar-loader-modal section-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_event_modal = _resolveComponent("event-modal")!
  const _component_modal_confirm = _resolveComponent("modal-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.calendarState.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_loader)
          ]))
        : _createCommentVNode("", true)
    ], 512),
    _withDirectives(_createVNode(_component_event_modal, null, null, 512), [
      [_vShow, _ctx.calendarState.eventModalVisible]
    ]),
    _withDirectives(_createVNode(_component_modal_confirm, {
      title: 'Sei sicuro di voler eliminare l\'evento ?',
      body: 'L\'evento non sara piu disponibile.',
      "show-btn-deny": true,
      showBtnOk: true,
      btnClickFn: _ctx.onDeleteEventConfirmClose
    }, null, 8, ["btnClickFn"]), [
      [_vShow, _ctx.calendarState.deleteEventConfirmVisible]
    ])
  ], 64))
}