import { COLUMN_HEAD_CELL_TYPE, ROW_HEAD_CELL_TYPE } from "../../common/PivotConsts";

type NodeCallback = (node: HTMLElement, isInteractive: boolean, onRow: boolean) => void;
export default class PivotNodeBubblingIterator {
  public static iterateNode(node: HTMLElement | null, nodeCallback: NodeCallback) {
    let interactiveElement = false;
    while (node?.nodeType === 1 && !interactiveElement) {
      const cellType = node.getAttribute("data-cell-type");
      if (cellType === ROW_HEAD_CELL_TYPE || cellType === COLUMN_HEAD_CELL_TYPE) {
        interactiveElement = true;
        nodeCallback(node, true, cellType === ROW_HEAD_CELL_TYPE);
      } else {
        nodeCallback(node, false, cellType === ROW_HEAD_CELL_TYPE);
        node = node.parentNode as HTMLElement;
      }
    }
  }
}
