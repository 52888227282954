import { CELL_HEIGHT, COLUMN_WIDTH, VALUES_SEPARATOR } from "../../common/PivotConsts";
import { IPivotChunkIndex } from "../../domain/PivotDS";
import { IPivotOptions } from "../../domain/PivotOptionsDS";
import { DomDrawState } from "../common/DomDrawState";

interface ICell {
  cssClass: string;
  textContent: string;
  iRowAbs: number;
  iColumnAbs: number;
  rowUnique: Array<string>;
  columnUnique: Array<string>;
}

interface IDrawContext {
  firstRowAbs: number;
  firstColumnAbs: number;
  pivotIndex: IPivotChunkIndex;
  opts: IPivotOptions;
  cellKeyLength: number;
  nRowsDOM: number;
  nColumnsDOM: number;
  i: number;
  j: number;
}

export default class PivotDataValues {
  private pivotDataValuesDOM: HTMLElement;
  private dataCells: Map<string, HTMLElement>;
  private bigTotalStartRow: number;
  private bigTotalStartColumn: number;

  constructor(pivotDataDOM: HTMLElement) {
    this.pivotDataValuesDOM = pivotDataDOM;
    this.dataCells = new Map();
    this.bigTotalStartRow = Infinity;
    this.bigTotalStartColumn = Infinity;
  }

  public calculateFullSizing() {
    const pivotIndex = DomDrawState.value().params.pivotIndex;
    const dataValues = DomDrawState.value().dataValues;
    dataValues.nAllDataColumns = pivotIndex?.allColumnsLength || 0;
    dataValues.nAllDataRows = pivotIndex?.allRowsLength || 0;
    dataValues.fullWidth = COLUMN_WIDTH * pivotIndex?.allColumnsLength || 0;
    dataValues.fullHeight = dataValues.nAllDataRows * CELL_HEIGHT;
  }

  private calculateDOMColumnSize() {
    const dataValues = DomDrawState.value().dataValues;
    const nColumnsDOM = Math.ceil(dataValues.domWidth / COLUMN_WIDTH) + 1;
    dataValues.columnsChanged = nColumnsDOM !== dataValues.nColumnsDom;
    dataValues.nColumnsDom = nColumnsDOM;
    const overAllColumns = dataValues.nColumnsDom > dataValues.nAllDataColumns;
    if (overAllColumns) {
      dataValues.nColumnsDom = dataValues.nAllDataColumns;
    }
  }

  private calculateDOMRowSize() {
    const dataValues = DomDrawState.value().dataValues;
    const nRowsDOM =
      Math.ceil(
        (DomDrawState.value().tableSize.currentHeight - DomDrawState.value().colHeadData.domHeight) / CELL_HEIGHT
      ) + 1;
    dataValues.rowsChanged = nRowsDOM !== dataValues.nRowsDom;
    dataValues.nRowsDom = nRowsDOM;
    const overAllRows = dataValues.nRowsDom > dataValues.nAllDataRows;
    if (overAllRows) {
      dataValues.nRowsDom = dataValues.nAllDataRows;
    }
  }

  public calculateDOMSizing() {
    const dataValues = DomDrawState.value().dataValues;
    dataValues.domWidth = Math.floor(
      DomDrawState.value().tableSize.currentWidth - DomDrawState.value().rowHeadData.domWidth
    );

    this.calculateDOMColumnSize();

    this.calculateDOMRowSize();
  }

  public calculateTotalsPositioning() {
    const opts = DomDrawState.value().params.opts;
    const dataValues = DomDrawState.value().dataValues;
    this.bigTotalStartRow = dataValues.nAllDataRows - (opts.measuresOnColumn ? 1 : opts.measures.length);
    if (opts.columns.length > 0) {
      this.bigTotalStartColumn =
        dataValues.nAllDataColumns - (opts.measuresOnColumn && opts.columns.length > 0 ? opts.measures.length : 1);
    }
  }

  public setCSSTranslateProp(translateRow: number, translateColumn: number) {
    this.pivotDataValuesDOM.style.setProperty("transform", `translate(${translateColumn}px,${translateRow}px)`);
  }

  public drawStructure() {
    this.dataCells.clear();
    for (let i = 0; i < DomDrawState.value().dataValues.nRowsDom; i++) {
      for (let j = 0; j < DomDrawState.value().dataValues.nColumnsDom; j++) {
        this.dataCells.set(`${i}-${j}`, document.createElement("div"));
      }
    }
    this.pivotDataValuesDOM.replaceChildren(...Array.from(this.dataCells.values()));
  }

  private getCellFullUnique(rowUnique: Array<string>, columnUnique: Array<string>, opts: IPivotOptions) {
    let result: Array<string> = [];
    if (opts.measuresOnColumn) {
      result = [...rowUnique, ...columnUnique];
    } else {
      const dimensionsPart = rowUnique.slice(0, rowUnique.length - 1);
      const measurePart = rowUnique[rowUnique.length - 1];
      result = [...dimensionsPart, ...columnUnique, measurePart];
    }

    return result;
  }

  private initDraw(): IDrawContext {
    const dataValues = DomDrawState.value().dataValues;
    const drawCtx = {
      firstRowAbs: DomDrawState.value().scroll.firstRowAbsolute,
      firstColumnAbs: DomDrawState.value().scroll.firstColumnAbsolute,
      pivotIndex: DomDrawState.value().params.pivotIndex,
      opts: DomDrawState.value().params.opts,
      nRowsDOM: dataValues.nRowsDom,
      nColumnsDOM: dataValues.nColumnsDom,
      cellKeyLength: 0,
      i: 0,
      j: 0,
    };
    drawCtx.cellKeyLength = drawCtx.opts.columns.length + drawCtx.opts.rows.length + 1;
    return drawCtx;
  }

  private initCellDraw(drawCtx: IDrawContext): ICell {
    const cell: ICell = {
      cssClass: "cell",
      textContent: "",
      iRowAbs: drawCtx.i + drawCtx.firstRowAbs,
      iColumnAbs: drawCtx.j + drawCtx.firstColumnAbs,
      rowUnique: new Array<string>(),
      columnUnique: new Array<string>(),
    };

    cell.rowUnique = drawCtx.pivotIndex.rowsUniques?.[cell.iRowAbs - DomDrawState.value().chunk.chunkStartRowAbsolute];
    cell.columnUnique =
      drawCtx.pivotIndex.columnsUniques?.[cell.iColumnAbs - DomDrawState.value().chunk.chunkStartColumnAbsolute];
    return cell;
  }

  private buildCell(cell: ICell, drawCtx: IDrawContext) {
    const cellFullUnique = this.getCellFullUnique(cell.rowUnique, cell.columnUnique, drawCtx.opts);
    const cellKey = cellFullUnique.join(VALUES_SEPARATOR);

    if (
      (drawCtx.opts.bigTotalOnRow && cell.iRowAbs >= this.bigTotalStartRow) ||
      (drawCtx.opts.bigTotalOnColumn && cell.iColumnAbs >= this.bigTotalStartColumn)
    ) {
      cell.cssClass += " total-cell";
    } else if (cellFullUnique.length < drawCtx.cellKeyLength) {
      cell.cssClass += " subtotal-cell";
    }

    cell.textContent = drawCtx.pivotIndex.valuesTreeMap.get(cellKey)?.toString() || "";
  }

  public drawData() {
    const drawCtx = this.initDraw();
    for (drawCtx.i = 0; drawCtx.i < drawCtx.nRowsDOM; drawCtx.i++) {
      for (drawCtx.j = 0; drawCtx.j < drawCtx.nColumnsDOM; drawCtx.j++) {
        const dataCellDOM = this.dataCells.get(`${drawCtx.i}-${drawCtx.j}`);

        if (dataCellDOM) {
          const cell = this.initCellDraw(drawCtx);

          if (cell.rowUnique && cell.columnUnique) {
            this.buildCell(cell, drawCtx);
          }

          dataCellDOM.setAttribute("class", cell.cssClass);
          dataCellDOM.textContent = cell.textContent;
        }
      }
    }
  }
}
