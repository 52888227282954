<template>
  <div :class="{ 'app-iconbar': true, 'iconbar-open': appState.isIconbarOpen }">
    <div
      class="burger-open"
      v-show="appState.currentPage === getCalendarPageId()"
      @click="appState.isIconbarOpen = true"
    >
      <menu-unfold-outlined :class="{ 'hide-burger-open': appState.isIconbarOpen, 'iconbar-icon': true }" />
    </div>

    <nav class="nav">
      <a
        @click="changePage(getCalendarPageId())"
        :class="{
          'nav-link': true,
          'iconbar-nav-link-active': appState.currentPage === getCalendarPageId(),
        }"
        ><calendar-outlined class="iconbar-icon" />
        Calendario
      </a>
      <a
        @click="changePage(getAnalysisPageId())"
        :class="{
          'nav-link': true,
          'iconbar-nav-link-active': appState.currentPage === getAnalysisPageId(),
        }"
        ><bar-chart-outlined class="iconbar-icon" />
        Analisi
      </a>
      <a
        @click="changePage(getUsersPageId())"
        :class="{
          'nav-link': true,
          'iconbar-nav-link-active': appState.currentPage === getUsersPageId(),
        }"
      >
        <team-outlined class="iconbar-icon" />Utenti
      </a>
      <a
        @click="changePage(getPerformancesPageId())"
        :class="{
          'nav-link': true,
          'iconbar-nav-link-active': appState.currentPage === getPerformancesPageId(),
        }"
      >
        <i class="las la-cog"></i>
        <scissor-outlined class="iconbar-icon" />
        Prestazioni
      </a>
    </nav>

    <div :class="{ 'aside-header': true, 'aside-header-show': appState.isIconbarOpen }">
      <logo></logo>
      <menu-fold-outlined
        :class="{
          'iconbar-icon': true,
          'burger-close': true,
        }"
        @click="appState.isIconbarOpen = false"
      />
    </div>
    <div :class="{ 'aside-body': true, 'aside-body-show': appState.isIconbarOpen }">
      <div class="aside-body-content">
        <date-picker
          :visible="appState.currentPage === getCalendarPageId() && appState.isIconbarOpen"
          :weekFilter="true"
          :maxRangeFilter="true"
          :maxRange="6"
          @change="calendarDatePickerChange"
          :plocKey="getCalendarPLOCKey()"
        ></date-picker>
        <div v-if="appState.currentPage === getCalendarPageId() && appState.isIconbarOpen">
          <calendar-filters></calendar-filters>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  CalendarOutlined,
  BarChartOutlined,
  TeamOutlined,
  ScissorOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons-vue";
import { manageSubscription } from "../../common/SubscriptionManager";
import { APP_PLOC_KEY, CALENDAR_PLOC_KEY } from "../../common/GUIConsts";
import { AppPLOC } from "../../../core/app-navigation/presentation/AppPLOC";
import {
  CALENDAR_PAGE_ID,
  ANALYSIS_PAGE_ID,
  USERS_PAGE_ID,
  PERFORMANCES_PAGE_ID,
} from "../../../core/app-navigation/domain/PagesConsts";
import DatePicker from "../controls/RangeDatePicker.vue";
import { CalendarPLOC } from "@/core/calendar/presentation/CalendarPLOC";
import CalendarFilters from "../calendar/CalendarFilters.vue";
import Logo from "@/components/internalApp/controls/Logo.vue";

export default defineComponent({
  components: {
    CalendarOutlined,
    BarChartOutlined,
    TeamOutlined,
    ScissorOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DatePicker,
    CalendarFilters,
    Logo,
  },
  setup() {
    const appPLOC = inject(APP_PLOC_KEY) as AppPLOC;
    const appState = manageSubscription(appPLOC);

    const calendarPLOC = inject(CALENDAR_PLOC_KEY) as CalendarPLOC;

    const getCalendarPageId = () => {
      return CALENDAR_PAGE_ID;
    };
    const getAnalysisPageId = () => {
      return ANALYSIS_PAGE_ID;
    };
    const getUsersPageId = () => {
      return USERS_PAGE_ID;
    };
    const getPerformancesPageId = () => {
      return PERFORMANCES_PAGE_ID;
    };

    const changePage = (pageId: string) => {
      appPLOC.onPageChange(pageId);
    };

    const calendarDatePickerChange = (newDateArray: Array<Date>) => {
      calendarPLOC.goToDateRange(newDateArray);
    };

    const getCalendarPLOCKey = () => {
      return CALENDAR_PLOC_KEY;
    };

    return {
      appState,
      getCalendarPageId,
      getAnalysisPageId,
      getUsersPageId,
      getPerformancesPageId,
      changePage,
      calendarDatePickerChange,
      getCalendarPLOCKey,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

$alignment-padding: 10px;
$open-width: 380px;
$icon-font-size: 24px;
$aside-delay: 0.25s;
$burger-close-delay: 0.5s;
$iconbar-closed-width: 89.5px;

.app-iconbar {
  z-index: 3;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: $gray-300;
  border-top: 1px solid $gray-400;

  .burger-open {
    display: none;
    cursor: pointer;
  }

  .burger-close {
    display: none;
    &:focus-visible {
      outline: none;
    }
  }

  .iconbar-icon {
    font-size: 18px;
  }

  nav {
    flex-wrap: nowrap;
    flex-direction: row;
    display: flex;

    .nav-link {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $color-primary;
      cursor: pointer;
      font-weight: 500;
      width: 100%;
      font-size: 0.75em;
      justify-content: center;
      height: $iconbar-navlink-height-mobile;
      &.iconbar-nav-link-active {
        color: #3366ff;
      }
    }
  }

  .aside-header {
    display: none;
    background: transparent;
    border-right: none;
    transition: all $aside-delay ease-out;

    .iconbar-icon {
      margin-right: 10px;
    }

    .logo {
      display: flex;
      flex-direction: row;
      padding-left: 10px;
      grid-row: 1;
      grid-column: 2;
      float: left;
      pointer-events: none;
      padding-top: 10px;
      color: transparent;
      .logo-img {
        height: 64px;
        width: 64px;
        margin-right: 5px;
      }
      .app-name {
        font-size: 23px;
        letter-spacing: 0px;
      }
      .slogan {
        font-size: 10px;
        letter-spacing: 0px;
      }
    }
  }

  .aside-body {
    display: none;
    .aside-body-content {
      display: none;
    }
  }
}

@media only screen and (min-width: 600px) {
  .app-iconbar {
    border-top: none;
    z-index: 0;
    position: relative;
    flex: none;
    width: $iconbar-closed-width;
    transition: all $aside-delay ease-in-out;
    display: grid;
    grid-template-columns: 0.5fr 3fr;
    grid-template-rows: 1fr 8fr;
    border-right: 1px solid $gray-400;
    height: auto;

    nav {
      flex-direction: column;
      grid-row: 2;
      grid-column: 1;
      width: $iconbar-closed-width;

      .nav-link {
        margin: 0px;
        padding: 0px 6px 10px 6px;
        font-size: 0.875rem;
        justify-content: inherit;
        height: auto;

        &.iconbar-nav-link-active {
          background-color: $gray-500;
          color: $color-primary;
          &::before {
            content: "";
            flex: none;
            position: absolute;
            top: auto;
            left: 0;
            width: 4px;
            background-color: $color-primary;
            height: $iconbar-navlink-height-desktop;
          }
        }

        &:hover {
          background: $gray-400;
        }
      }
    }

    .aside-header {
      justify-content: space-between;
      align-items: center;
    }

    .iconbar-icon {
      font-size: 24px;
      padding: 10px 10px 7px 10px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .app-iconbar {
    border-top: none;
    z-index: 0;

    .aside-body {
      display: block;
      color: transparent;
      background: transparent;
      transition: all $aside-delay ease-out;
      grid-row: 2;
      grid-column: 2;
      .aside-body-content {
        display: block;
      }
    }

    .burger-open {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-row: 1;
      grid-column: 1;
    }

    .burger-close {
      display: block;
      cursor: pointer;
      color: transparent;
      transition: color $aside-delay ease-in;
    }

    .logo {
      transition: color $aside-delay ease-in;
    }

    .hide-burger-open {
      color: transparent;
      transition: color $aside-delay ease-out;
    }

    .aside-header-show {
      background: $gray-200;
      transition: background $aside-delay ease-in;
      border-left: 1px solid $gray-400;
      display: flex;

      .logo {
        color: $color-secondary;
      }

      .burger-close {
        color: $color-secondary;
        transition: color $burger-close-delay ease-in;
      }
    }

    .aside-body-show {
      color: $color-primary;
      background: $gray-200;
      transition: color $aside-delay ease-in, background $aside-delay ease-in;
      border-left: 1px solid $gray-400;
    }
  }
  .iconbar-open {
    width: $open-width;
    transition: all $aside-delay ease-in;
  }
}
</style>
