import { Login } from "./Login";
import { LoginResponse } from "./LoginResponse";
import { User } from "../../users/domain/User";

export default class DoLoginUseCase {
  static async doLogin(login: Login, mail: string, password: string, captchaToken: string): Promise<LoginResponse> {
    if (User.checkMail(mail) && captchaToken) {
      const response = await login.doLogin(mail, password, captchaToken);
      if (response.success) localStorage.setItem(Login.LOGGED_USER_KEY, response.userId);
      return response;
    } else
      return Promise.resolve({
        success: false,
        errorMsg: "",
        userId: "",
        user: {} as User,
      });
  }

  static tryLoadLoggedUser(): string {
    return localStorage.getItem(Login.LOGGED_USER_KEY) as string;
  }
}
