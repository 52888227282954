import { IOpening, IOptionsApplied, IPivotChunkIndex } from "../../domain/PivotDS";
import IOnMessageResponseInternal from "../../worker/IOnMessageResponseInternal";
import { DomDrawState } from "../common/DomDrawState";
import PivotTable from "../PivotTable";

export default class WorkerListener extends IOnMessageResponseInternal {
  private pivotContainer: PivotTable;

  constructor(pivotContainer: PivotTable) {
    super();
    this.pivotContainer = pivotContainer;
  }

  public onApplyOptions(result: IOptionsApplied) {
    DomDrawState.value().params.pivotIndex = result.pivotIndex;
    DomDrawState.value().params.dataChanged = true;
    DomDrawState.value().params.opts = result.opts;
    this.pivotContainer.draw();
  }

  private updatePivotIndexPartial(pivotIndex: IPivotChunkIndex, result: IOpening) {
    if (result.onRow) {
      pivotIndex.rowsUniques = result.newUniques;
      pivotIndex.maxOpenedFieldsOnRow = result.maxOpenedFields;
      pivotIndex.openedRows = result.newOpened;
      pivotIndex.allRowsLength = result.allUniquesLength;
    } else {
      pivotIndex.columnsUniques = result.newUniques;
      pivotIndex.maxOpenedFieldsOnColumn = result.maxOpenedFields;
      pivotIndex.openedColumns = result.newOpened;
      pivotIndex.allColumnsLength = result.allUniquesLength;
    }
    pivotIndex.valuesTreeMap = result.newValuesTreeMap;
    pivotIndex.codeToDesc = result.newCodeToDesc;
  }

  public onToggleCellOpening(result: IOpening) {
    const pivotIndex = DomDrawState.value().params.pivotIndex;

    this.updatePivotIndexPartial(pivotIndex, result);

    DomDrawState.value().params.dataChanged = true;
    this.pivotContainer.draw();
  }

  private updatePivotIndexComplete(toUpdate: IPivotChunkIndex, upToDate: IPivotChunkIndex) {
    toUpdate.rowsUniques = upToDate.rowsUniques;
    toUpdate.columnsUniques = upToDate.columnsUniques;
    toUpdate.valuesTreeMap = upToDate.valuesTreeMap;
    toUpdate.codeToDesc = upToDate.codeToDesc;
    toUpdate.openedRows = upToDate.openedRows;
    toUpdate.openedColumns = upToDate.openedColumns;
    toUpdate.maxOpenedFieldsOnRow = upToDate.maxOpenedFieldsOnRow;
    toUpdate.maxOpenedFieldsOnColumn = upToDate.maxOpenedFieldsOnColumn;
    toUpdate.allRowsLength = upToDate.allRowsLength;
    toUpdate.allColumnsLength = upToDate.allColumnsLength;
    toUpdate.rowRange = upToDate.rowRange;
    toUpdate.columnRange = upToDate.columnRange;
  }

  public onGetPivotChunk(result: IPivotChunkIndex) {
    const pivotIndex = DomDrawState.value().params.pivotIndex;

    this.updatePivotIndexComplete(pivotIndex, result);

    DomDrawState.value().chunk.chunkStartRowAbsolute = result.rowRange[0];
    DomDrawState.value().chunk.chunkEndRowAbsolute = result.rowRange[1];
    DomDrawState.value().chunk.chunkStartColumnAbsolute = result.columnRange[0];
    DomDrawState.value().chunk.chunkEndColumnAbsolute = result.columnRange[1];
    DomDrawState.value().params.dataChanged = true;

    this.pivotContainer.draw();
  }
}
