import UsersPLOC from "@/core/users/presentation/UsersPLOC";
import {
  IDateFilterValue,
  IEntity,
  IFilter,
  IListFilterValue,
  IPivotOptions,
  TFilterValues,
  allDimensions,
} from "../PivotOptionsDS";
import PivotEntitiesUtils from "./PivotEntitiesUtils";
import { CUSTOMER_DIM_CODE, DATE_DIM_CODE, EMPLOYEES_DIM_CODE, PERFORMANCE_DIM_CODE } from "../../common/PivotConsts";
import PerformancesPLOC from "@/core/performances/presentation/PerformancesPLOC";
import PivotFormatter from "../pivotParts/PivotFormatter";
import PivotData from "../PivotData";

export interface IPivotFiltersEditing {
  editFilterMode: boolean;
  filterOnEdit: IFilter;
  filterSubtitle: string;
  filterSaveErrorMsg: string;
}

export default class PivotFiltersEditing {
  public static dimAliasToValues: Map<string, Array<string>> = new Map();

  //Contiene i valori selezionati eventualmente modificati: usati in modifica di un filtro,
  //se la modifica del filtro viene salvata poi vengono mergiati in selectedValues
  private static workingSelectedValues: Map<string, TFilterValues> = new Map();

  //Contiene i valori selezionati originali provenienti dal server: usati quando si salva la form dei filtri
  private static selectedValues: Map<string, TFilterValues> = new Map();

  private static reset(resetAll = false) {
    //Resetto i valori delle dimensioni e lo stato di selezione
    PivotFiltersEditing.workingSelectedValues = new Map();
    if (resetAll === true) {
      PivotFiltersEditing.selectedValues = new Map();
      PivotFiltersEditing.dimAliasToValues = new Map();
    }
  }

  public static getFilterDescription(filter: IFilter, opts: IPivotOptions) {
    let result = "Errore, descrizione non trovata";
    PivotEntitiesUtils.getSelectedDimensions(opts).forEach((dim: IEntity) => {
      if (dim.alias === filter.entityAlias) result = "Filtro su " + dim.desc;
    });

    return result;
  }

  public static createNewFilter() {
    //Nuovo filtro: lo creo da zero
    const filter = {} as IFilter;
    return { filterOnEdit: filter, filterSubtitle: "Nuovo filtro", editFilterMode: true };
  }

  private static loadAndCopySelectedValues(entity: string, pivotData: PivotData) {
    //Carico i valori originali
    if (!PivotFiltersEditing.selectedValues.has(entity)) {
      const values = pivotData.getFilterSelectedValues(entity);
      PivotFiltersEditing.selectedValues.set(entity, values);
    }

    //Li copio
    if (entity === DATE_DIM_CODE)
      this.workingSelectedValues.set(
        entity,
        JSON.parse(JSON.stringify(PivotFiltersEditing.selectedValues.get(entity)))
      );
    else this.workingSelectedValues.set(entity, new Set(<Set<string>>PivotFiltersEditing.selectedValues.get(entity)));
  }

  public static editFilter(
    filterToEdit: IFilter,
    pivotData: PivotData,
    usersPLOC: UsersPLOC,
    performancesPLOC: PerformancesPLOC
  ) {
    //Modifica di un filtro esistente: faccio una copia di lavoro sia del filtro che dei valori selezionati
    PivotFiltersEditing.loadAndCopySelectedValues(filterToEdit.entityAlias, pivotData);
    PivotFiltersEditing.loadFilterValues(filterToEdit, usersPLOC, performancesPLOC);
    return {
      filterOnEdit: JSON.parse(JSON.stringify(filterToEdit)),
      filterSubtitle: "Modifica filtro",
      editFilterMode: true,
    };
  }

  private static loadFilterValues(filter: IFilter, usersPLOC: UsersPLOC, performancesPLOC: PerformancesPLOC) {
    const entity = filter.entityAlias;
    //Se non li ho gia' carico i valori dell'entita' nuova
    if (!PivotFiltersEditing.dimAliasToValues.has(entity))
      switch (entity) {
        case CUSTOMER_DIM_CODE:
          PivotFiltersEditing.dimAliasToValues.set(CUSTOMER_DIM_CODE, usersPLOC.getAllCustomersIds());
          break;
        case EMPLOYEES_DIM_CODE:
          PivotFiltersEditing.dimAliasToValues.set(EMPLOYEES_DIM_CODE, usersPLOC.getAllEmployeesIds());
          break;
        case PERFORMANCE_DIM_CODE:
          PivotFiltersEditing.dimAliasToValues.set(PERFORMANCE_DIM_CODE, performancesPLOC.getAllPerformancesIds());
          break;
      }

    if (entity !== DATE_DIM_CODE) {
      const dimValues = <Array<string>>PivotFiltersEditing.dimAliasToValues.get(entity);
      filter.allValuesCount = dimValues.length;
    }
  }

  public static changeFilterEntity(
    entity: string,
    filter: IFilter,
    usersPLOC: UsersPLOC,
    performancesPLOC: PerformancesPLOC,
    pivotData: PivotData
  ) {
    //Cambio di entita' legata ad un filtro nuovo o in modifica
    filter.entityAlias = entity;

    PivotFiltersEditing.loadFilterValues(filter, usersPLOC, performancesPLOC);

    //Al cambio di entita' sia da nuovo che in modifica ricarico i valori selezionati e li copio
    PivotFiltersEditing.loadAndCopySelectedValues(entity, pivotData);
  }

  public static getListFilterValue(
    index: number,
    filter: IFilter,
    pivotFormatter: PivotFormatter,
    opts: IPivotOptions
  ): IListFilterValue {
    //Dall'indice del valore recupero il valore della dimensione associato
    const selectedValues = <Set<string>>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
    const dimValues = <Array<string>>PivotFiltersEditing.dimAliasToValues.get(filter.entityAlias);
    if (dimValues && selectedValues && dimValues.length > index) {
      const id = dimValues[index];
      const entityFormat = PivotEntitiesUtils.getDimensionFormat(filter.entityAlias, opts);
      const description = pivotFormatter.formatDimension(id, filter.entityAlias, entityFormat);
      return { id, description, selected: selectedValues.has(id) };
    } else return { id: index + "not_found", description: "Filtro non trovato", selected: false };
  }

  public static toggleFilterSelectedValue(filter: IFilter, value: string) {
    //Modifico lo stato di selezione dei valori del filtro sulla copia di lavoro
    const selectedValues = <Set<string>>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
    if (selectedValues && selectedValues.has(value)) selectedValues.delete(value);
    else selectedValues.add(value);

    return PivotFiltersEditing.checkFilterOk(filter);
  }

  public static selectAll(filter: IFilter) {
    const selectedValues = <Set<string>>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
    const dimValues = <Array<string>>PivotFiltersEditing.dimAliasToValues.get(filter.entityAlias);
    if (dimValues) dimValues.forEach((dimValue) => selectedValues.add(dimValue));
  }

  public static selectOne(filter: IFilter, value: string) {
    const newSelected = new Set<string>();
    newSelected.add(value);
    PivotFiltersEditing.workingSelectedValues.set(filter.entityAlias, newSelected);
  }

  public static selectNone(filter: IFilter) {
    const newSelected = new Set<string>();
    PivotFiltersEditing.workingSelectedValues.set(filter.entityAlias, newSelected);
    return { filterSaveErrorMsg: PivotFiltersEditing.checkFilterOk(filter) };
  }

  public static exitEditFilterMode() {
    //Annullo la creazione o modifica di un filtro quindi resetto lo stato buttando via le modifiche relative al filtro
    PivotFiltersEditing.reset();
    return {
      editFilterMode: false,
      filterOnEdit: {} as IFilter,
      filterSubtitle: "Elenco filtri attivi",
      filterSaveErrorMsg: "",
      modalVisible: true,
    };
  }

  public static getFilterableDims(opts: IPivotOptions) {
    //Restituisce le dimensioni che non sono presenti in currentFilters, questo garantisce che i filtri non si sovrappongano quando si va in modifica
    const result = new Array<string>();
    const selectedDims = PivotEntitiesUtils.getSelectedDimensions(opts);
    allDimensions.forEach((dim) => {
      if (
        !opts.filters.some((filter) => filter.entityAlias === dim.alias) &&
        selectedDims.some((entity) => entity.alias === dim.alias)
      )
        result.push(dim.alias);
    });
    return result;
  }

  public static onClose(editFilterMode: boolean) {
    if (editFilterMode) return PivotFiltersEditing.exitEditFilterMode();
    else {
      //Chiusura della form intera, butto via tutto resettando lo stato (tutte le modifiche di tutti i filtri)
      PivotFiltersEditing.reset(true);
      return {
        editFilterMode: false,
        modalVisible: editFilterMode,
        filterOnEdit: {} as IFilter,
        filterSaveErrorMsg: "",
        filterSubtitle: "",
      };
    }
  }

  private static checkFilterOk(filter: IFilter) {
    if (filter && filter.entityAlias) {
      if (PivotFiltersEditing.workingSelectedValues.has(filter.entityAlias))
        if (filter.entityAlias === DATE_DIM_CODE) {
          const filterValues = <IDateFilterValue>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
          return filterValues.start <= filterValues.end
            ? ""
            : "Filtro non valido: la data di inizio deve essere precedente o uguale alla data di fine";
        } else {
          const filterValues = <Set<string>>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
          return filterValues.size > 0 ? "" : "Filtro non valido: selezionare almeno un valore";
        }
      else return "Errore filtro: valori del filtro non trovati.";
    } else return "Selezionare la dimensione da filtrare.";
  }

  public static onSave(
    editFilterMode: boolean,
    filterOnEdit: IFilter,
    pivotData: PivotData,
    workingFilters: Array<IFilter>
  ) {
    const result = { editFilterMode: true, modalVisible: false, filterOnEdit, filterSaveErrorMsg: "" };

    if (editFilterMode) {
      result.filterSaveErrorMsg = PivotFiltersEditing.checkFilterOk(filterOnEdit);
      if (result.filterSaveErrorMsg === "") {
        //Resetto lo stato: la form di modifica si chiuse, il filtro in edit si resetta
        result.editFilterMode = false;
        result.filterOnEdit = {} as IFilter;

        //Salvo il filtro nuovo o in modifica: se e' nuovo lo aggiungo alla copia di lavoro dei filtri,
        //altrimenti lo sostituisco a quello presente in precedenza sempre in copia di lavoro
        const isNew = !workingFilters.some((filter) => filter.entityAlias === filterOnEdit.entityAlias);
        if (isNew) workingFilters.push(filterOnEdit);
        else {
          const workingFilterIdx = workingFilters.findIndex((filter) => {
            filter.entityAlias === filterOnEdit.entityAlias;
          });
          workingFilters[workingFilterIdx] = filterOnEdit;
        }

        //A prescindere da nuovo o in modifica salvo i valori selezionati: li sposto dalla copia di lavoro a quelli originali
        const workingSelectedValues = <TFilterValues>(
          PivotFiltersEditing.workingSelectedValues.get(filterOnEdit.entityAlias)
        );
        PivotFiltersEditing.selectedValues.set(filterOnEdit.entityAlias, workingSelectedValues);
      }

      result.modalVisible = true;
    } else {
      //Salvataggio globale di tutte le modifiche fatte ai filtri: salvo lo stato di selezione relativo ai soli filtri rimasti nella copia di lavoro buttando via il resto
      const valuesToSave = new Map();
      workingFilters.forEach((filter) => {
        //attenzione: salvo solo le modifiche fatte alla seleizione: selectedValues e' popolato solo se si e' entrati in modifica
        if (PivotFiltersEditing.selectedValues.has(filter.entityAlias))
          valuesToSave.set(filter.entityAlias, PivotFiltersEditing.selectedValues.get(filter.entityAlias));
      });
      pivotData.saveFiltersValues(valuesToSave);

      result.editFilterMode = false;

      PivotFiltersEditing.reset(true);
    }
    return result;
  }

  public static getTemporalFilterValue(filter: IFilter) {
    if (PivotFiltersEditing.workingSelectedValues.has(filter.entityAlias))
      return <IDateFilterValue>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
  }

  public static isNewFilter(workingFilters: Array<IFilter>, filterOnEdit: IFilter) {
    return !workingFilters.some((filter) => filter.entityAlias === filterOnEdit.entityAlias);
  }

  public static setTemporalFilterValue(filter: IFilter, value: Date, isStart: boolean) {
    if (PivotFiltersEditing.workingSelectedValues.has(filter.entityAlias)) {
      const filterValues = <IDateFilterValue>PivotFiltersEditing.workingSelectedValues.get(filter.entityAlias);
      if (filterValues)
        if (isStart) filterValues.start = value;
        else filterValues.end = value;
      else return "Errore: entita' di filtro non trovata";
      return PivotFiltersEditing.checkFilterOk(filter);
    } else return "Errore: entita' di filtro non trovata";
  }

  public static removeFilter(entityAlias: string, workingFilters: Array<IFilter>) {
    return workingFilters.filter((filter) => filter.entityAlias !== entityAlias);
  }
}
