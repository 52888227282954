<template>
  <div class="modal pivot-modal pivot-entities-modal">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="closeModal()"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><deployment-unit-outlined></deployment-unit-outlined></div>
        <h3>Gestione Entitá</h3>
      </div>
      <div class="modal-body">
        <div class="entity-panels">
          <div class="panels-group">
            <div class="entity-panel">
              <div class="panel-header">Dimensioni disponibili</div>
              <div
                class="panel-body available-dims"
                @drop="onDropEntity($event, getAvailableDimensionsListId())"
                @dragenter.prevent
                @dragover.prevent="onDragOver($event, getAvailableDimensionsListId())"
              >
                <div
                  v-for="dimension in pivotState.availableDimensions"
                  v-bind:key="dimension.alias"
                  :id="dimension.alias"
                  :list-id="getAvailableDimensionsListId()"
                  class="panel-item"
                  draggable="true"
                  @dragstart="startDragEntity($event, dimension.alias, getAvailableDimensionsListId())"
                  @touchend="doubleTap.tap($event, onEntityDoubleClick)"
                  @click="doubleTap.tap($event, onEntityDoubleClick)"
                >
                  <div class="entity-icon dimension"></div>
                  {{ dimension.desc }}
                </div>
              </div>
            </div>
            <div class="entity-panel">
              <div class="panel-header">Misure disponibili</div>
              <div
                class="panel-body"
                @drop="onDropEntity($event, getAvailableMeasuresListId())"
                @dragover.prevent="onDragOver($event, getAvailableMeasuresListId())"
                @dragenter.prevent
              >
                <div
                  v-for="measure in pivotState.availableMeasures"
                  v-bind:key="measure.alias"
                  :id="measure.alias"
                  :list-id="getAvailableMeasuresListId()"
                  draggable="true"
                  class="panel-item"
                  @dragstart="startDragEntity($event, measure.alias, getAvailableMeasuresListId())"
                  @touchend="doubleTap.tap($event, onEntityDoubleClick)"
                  @click="doubleTap.tap($event, onEntityDoubleClick)"
                >
                  <div class="entity-icon measure"></div>
                  {{ measure.desc }}
                </div>
              </div>
            </div>
          </div>
          <div class="drag-icons">
            <drag-outlined></drag-outlined>
            <swap-outlined></swap-outlined>
          </div>
          <div class="panels-group">
            <div class="entity-panel">
              <div class="panel-header">Dimensioni selezionate in riga</div>
              <div
                class="panel-body"
                @drop="onDropEntity($event, getRowsListId())"
                @dragover.prevent="onDragOver($event, getRowsListId())"
                @dragenter.prevent
              >
                <div
                  v-for="selectedDim in pivotState.optsWorkingCopy.rows"
                  v-bind:key="selectedDim.alias"
                  :id="selectedDim.alias"
                  :list-id="getRowsListId()"
                  class="panel-item selected"
                  draggable="true"
                  @dragstart="startDragEntity($event, selectedDim.alias, getRowsListId())"
                  @touchend="doubleTap.tap($event, onEntityDoubleClick)"
                  @click="doubleTap.tap($event, onEntityDoubleClick)"
                >
                  <div class="entity-icon dimension"></div>
                  {{ selectedDim.desc }}
                </div>
              </div>
            </div>
            <div class="entity-panel">
              <div class="panel-header">Dimensioni selezionate in colonna</div>
              <div
                class="panel-body"
                @drop="onDropEntity($event, getColumnsListId())"
                @dragover.prevent="onDragOver($event, getColumnsListId())"
                @dragenter.prevent
                @touchend="doubleTap.tap($event, onEntityDoubleClick)"
                @click="doubleTap.tap($event, onEntityDoubleClick)"
              >
                <div
                  v-for="selectedDim in pivotState.optsWorkingCopy.columns"
                  v-bind:key="selectedDim.alias"
                  :id="selectedDim.alias"
                  :list-id="getColumnsListId()"
                  class="panel-item selected"
                  draggable="true"
                  @dragstart="startDragEntity($event, selectedDim.alias, getColumnsListId())"
                >
                  <div class="entity-icon dimension"></div>
                  {{ selectedDim.desc }}
                </div>
              </div>
            </div>
            <div class="entity-panel">
              <div class="panel-header">Misure selezionate</div>
              <div
                class="panel-body"
                @drop="onDropEntity($event, getSelectedMeasuresListId())"
                @dragover.prevent="onDragOver($event, getSelectedMeasuresListId())"
                @dragenter.prevent
                @touchend="doubleTap.tap($event, onEntityDoubleClick)"
                @click="doubleTap.tap($event, onEntityDoubleClick)"
              >
                <div
                  v-for="selectedMeas in pivotState.optsWorkingCopy.measures"
                  v-bind:key="selectedMeas.alias"
                  :id="selectedMeas.alias"
                  :list-id="getSelectedMeasuresListId()"
                  class="panel-item selected"
                  draggable="true"
                  @dragstart="startDragEntity($event, selectedMeas.alias, getSelectedMeasuresListId())"
                >
                  <div class="entity-icon measure"></div>
                  {{ selectedMeas.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveOptions">
          {{ getSaveString() }}
        </button>

        <button class="btn btn-outline btn-outline-discard" @click="closeModal">
          {{ getDiscardString() }}
        </button>
      </div>
    </div>
  </div>

  <modal-confirm
    v-show="!pivotState.entitiesOk && pivotState.showPivotEntitiesMsg"
    :title="'Configurazione errata'"
    :body="'Selezionare almeno una dimensione in riga e almeno una misura'"
    :showBtnOk="true"
    :btnClickFn="closePivotEntitiesMsg"
  ></modal-confirm>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined, DeploymentUnitOutlined, DragOutlined, SwapOutlined } from "@ant-design/icons-vue";
import { DISCARD, SAVE } from "@/core/common/utils/ResourceStrings";
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import ModalConfirm from "../../controls/ModalConfirm.vue";
import PivotEntitiesMover from "@/core/pivot/draw/configurationParts/PivotEntitiesMover";
import {
  AVAILABLE_DIMENSIONS_LIST_ID,
  AVAILABLE_MEASURES_LIST_ID,
  COLUMNS_LIST_ID,
  ENTITIES_MODAL_ID,
  ROWS_LIST_ID,
  SELECTED_MEASURES_LIST_ID,
} from "@/core/pivot/common/PivotConsts";
import DoubleTap from "@/core/common/utils/DoubleTap";
export default defineComponent({
  components: { CloseOutlined, DeploymentUnitOutlined, DragOutlined, SwapOutlined, ModalConfirm },
  setup() {
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);

    const entitiesMover = new PivotEntitiesMover(
      pivotState.value.optsWorkingCopy,
      pivotState.value.availableDimensions,
      pivotState.value.availableMeasures
    );
    const doubleTap = new DoubleTap();

    const closeModal = () => {
      pivotPLOC.closeOptionsModal();
    };

    const getSaveString = () => {
      return SAVE;
    };
    const getDiscardString = () => {
      return DISCARD;
    };

    const saveOptions = async () => {
      await pivotPLOC.saveOptions(ENTITIES_MODAL_ID);
    };
    const closePivotEntitiesMsg = () => {
      pivotState.value.showPivotEntitiesMsg = false;
    };

    const startDragEntity = (evt: DragEvent, alias: string, fromId: string) => {
      entitiesMover.startDragEntity(evt, alias, fromId);
    };

    const onDropEntity = (evt: DragEvent, targetListId: string) => {
      entitiesMover.onDropEntity(evt, targetListId);
    };

    const onDragOver = (evt: DragEvent, targetListId: string) => {
      entitiesMover.onDragOver(evt, targetListId);
    };

    const getRowsListId = () => {
      return ROWS_LIST_ID;
    };

    const getColumnsListId = () => {
      return COLUMNS_LIST_ID;
    };

    const getSelectedMeasuresListId = () => {
      return SELECTED_MEASURES_LIST_ID;
    };

    const getAvailableDimensionsListId = () => {
      return AVAILABLE_DIMENSIONS_LIST_ID;
    };
    const getAvailableMeasuresListId = () => {
      return AVAILABLE_MEASURES_LIST_ID;
    };
    const onEntityDoubleClick = (evt: Event) => {
      entitiesMover.onEntityDoubleClick(evt);
    };

    return {
      getRowsListId,
      getColumnsListId,
      getSelectedMeasuresListId,
      getAvailableDimensionsListId,
      getAvailableMeasuresListId,
      getSaveString,
      getDiscardString,
      closeModal,
      saveOptions,
      pivotState,
      startDragEntity,
      onDropEntity,
      onDragOver,
      closePivotEntitiesMsg,
      doubleTap,
      onEntityDoubleClick,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

.pivot-entities-modal {
  .modal-content {
    .modal-body {
      align-items: center;
      .not-allowed {
        cursor: not-allowed;
      }

      .entity-panels {
        display: flex;
        flex-direction: row;

        @media only screen and (max-width: 550px) {
          flex-direction: column;
        }

        .drag-icons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          margin: 10px;

          @media only screen and (max-width: 550px) {
            display: none;
          }
        }
        .panels-group {
          display: flex;
          flex-direction: column;

          .entity-panel {
            margin: 5px;
            width: 250px;

            .panel-header {
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
              padding: 5px;
              background: $gray-500;
              color: white;
            }

            .panel-body {
              padding: 5px;
              height: 90px;
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
              border: 1px solid $gray-200;
              border-top: none;
              @media only screen and (max-width: 550px) {
                height: 80px;
              }

              &.available-dims {
                height: 220px;
                @media only screen and (max-width: 550px) {
                  height: 80px;
                }
              }

              .panel-item {
                display: flex;
                align-items: center;
                background: transparent;
                cursor: all-scroll;

                .entity-icon {
                  border-radius: 2px;
                  margin-right: 5px;
                  height: 10px;
                  width: 10px;
                  &.dimension {
                    border: 1px solid rgb(0, 204, 255);
                  }
                  &.measure {
                    border: 1px solid rgb(192, 98, 247);
                  }
                }

                &.selected {
                  .entity-icon {
                    &.dimension {
                      background: rgb(166, 229, 248);
                    }
                    &.measure {
                      background: rgb(227, 179, 255);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
@/core/pivot/domStructure/PivotEntitiesMover @/core/pivot/draw/PivotEntitiesMover
