import { CHUNK_SIZE_FACTOR, GET_PIVOT_CHUNK_MSG } from "../../common/PivotConsts";
import IWorkerPostMessage from "../../worker/IWorkerPostMessage";
import { DomDrawState } from "../common/DomDrawState";

interface IChunkRequest {
  rowStart: number;
  rowEnd: number;
  columnStart: number;
  columnEnd: number;
}
export default class PivotChunk {
  private nVisibleDataRows: number;
  private chunkRowSize: number;
  private nVisibleDataColumns: number;
  private chunkColumnSize: number;
  private remainingChunkMiddleAbsolute: Array<number>;
  private lastPivotChunkRequest: IChunkRequest | null;
  private worker: IWorkerPostMessage;

  constructor(worker: IWorkerPostMessage) {
    this.nVisibleDataRows = 0;
    this.chunkRowSize = 0;
    this.nVisibleDataColumns = 0;
    this.chunkColumnSize = 0;
    this.remainingChunkMiddleAbsolute = [];
    this.lastPivotChunkRequest = {} as IChunkRequest;
    this.worker = worker;
  }

  public resetChunk() {
    DomDrawState.value().chunk.chunkStartRowAbsolute = 0;
    DomDrawState.value().chunk.chunkEndRowAbsolute = 0;
    DomDrawState.value().chunk.chunkStartColumnAbsolute = 0;
    DomDrawState.value().chunk.chunkEndColumnAbsolute = 0;
  }

  public init() {
    this.nVisibleDataRows = DomDrawState.value().dataValues.nRowsDom;
    this.chunkRowSize = DomDrawState.value().dataValues.nRowsDom * CHUNK_SIZE_FACTOR;
    this.nVisibleDataColumns = DomDrawState.value().dataValues.nColumnsDom;
    this.chunkColumnSize = DomDrawState.value().dataValues.nColumnsDom * CHUNK_SIZE_FACTOR;
  }

  public calculateChunk() {
    const firstRowAbsolute = DomDrawState.value().scroll.firstRowAbsolute;
    const firstColumnAbsolute = DomDrawState.value().scroll.firstColumnAbsolute;
    const nRowsDOM = DomDrawState.value().dataValues.nRowsDom;
    const nColumnsDOM = DomDrawState.value().dataValues.nColumnsDom;
    const nAllRows = DomDrawState.value().dataValues.nAllDataRows;
    const nAllColumns = DomDrawState.value().dataValues.nAllDataColumns;

    let newChunkStartRowAbsolute = DomDrawState.value().chunk.chunkStartRowAbsolute;
    let newChunkEndRowAbsolute = DomDrawState.value().chunk.chunkEndRowAbsolute;
    let newChunkStartColumnAbsolute = DomDrawState.value().chunk.chunkStartColumnAbsolute;
    let newChunkEndColumnAbsolute = DomDrawState.value().chunk.chunkEndColumnAbsolute;

    this.remainingChunkMiddleAbsolute[0] = firstRowAbsolute + Math.ceil(nRowsDOM / 2);
    this.remainingChunkMiddleAbsolute[1] = firstColumnAbsolute + Math.ceil(nColumnsDOM / 2);

    const nearToEndRow =
      newChunkEndRowAbsolute < nAllRows &&
      newChunkEndRowAbsolute - this.remainingChunkMiddleAbsolute[0] < this.nVisibleDataRows;
    const nearToStartRow =
      newChunkStartRowAbsolute > 0 &&
      this.remainingChunkMiddleAbsolute[0] - newChunkStartRowAbsolute < this.nVisibleDataRows;
    const nearToEndColumn =
      newChunkEndColumnAbsolute < nAllColumns &&
      newChunkEndColumnAbsolute - this.remainingChunkMiddleAbsolute[1] < this.nVisibleDataColumns;
    const nearToStartColumn =
      newChunkStartColumnAbsolute > 0 &&
      this.remainingChunkMiddleAbsolute[1] - newChunkStartColumnAbsolute < this.nVisibleDataColumns;
    const needNewChunkForRow = nearToEndRow || nearToStartRow;
    const needNewChunkForColumn = nearToEndColumn || nearToStartColumn;

    const needNewChunk = needNewChunkForRow || needNewChunkForColumn;

    if (needNewChunk) {
      newChunkStartRowAbsolute = this.remainingChunkMiddleAbsolute[0] - this.chunkRowSize;
      if (newChunkStartRowAbsolute < 0) {
        newChunkStartRowAbsolute = 0;
      }
      newChunkEndRowAbsolute = this.remainingChunkMiddleAbsolute[0] + this.chunkRowSize;
      if (newChunkEndRowAbsolute > nAllRows) {
        newChunkEndRowAbsolute = nAllRows;
      }

      newChunkStartColumnAbsolute = this.remainingChunkMiddleAbsolute[1] - this.chunkColumnSize;
      if (newChunkStartColumnAbsolute < 0) {
        newChunkStartColumnAbsolute = 0;
      }
      newChunkEndColumnAbsolute = this.remainingChunkMiddleAbsolute[1] + this.chunkColumnSize;
      if (newChunkEndColumnAbsolute > nAllColumns) {
        newChunkEndColumnAbsolute = nAllColumns;
      }

      this.lastPivotChunkRequest = {
        rowStart: newChunkStartRowAbsolute,
        rowEnd: newChunkEndRowAbsolute,
        columnStart: newChunkStartColumnAbsolute,
        columnEnd: newChunkEndColumnAbsolute,
      };
    }
  }

  public getPivotChunk = () => {
    if (this.lastPivotChunkRequest && !this.worker.isWaitingForPivotChunk()) {
      this.worker.postMessage({
        type: GET_PIVOT_CHUNK_MSG,
        props: this.lastPivotChunkRequest,
      });
      this.lastPivotChunkRequest = null;
      this.worker.setWaitingForPivotChunk(true);
    }
  };
}
