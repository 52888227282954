<template>
  <Login v-show="appState.currentPage === getLoginPageId()"></Login>

  <splash-screen v-if="appState.currentPage === getSplashScreenPageId()"></splash-screen>
  <InternalApp v-else-if="appState.currentPage !== getLoginPageId() && appState.loggedIn"></InternalApp>

  <modal-confirm
    v-show="appState.sessionExpired"
    :title="'Sessione scaduta'"
    :body="'Sarà necessario rieffettuare il login'"
    :showBtnOk="true"
    :btnClickFn="onSessionExpiredOk"
  ></modal-confirm>
</template>

<script lang="ts">
import { defineComponent, provide, onMounted } from "vue";
import Login from "./components/login/Login.vue";
import { HTTP_CLIENT_KEY, APP_PLOC_KEY, FACTORY_KEY, LOGIN_PLOC_KEY } from "./components/common/GUIConsts";
import InternalApp from "./components/internalApp/InternalApp.vue";
import { manageSubscription } from "./components/common/SubscriptionManager";
import { LOGIN_PAGE_ID, SPLASH_SCREEN_PAGE_ID } from "./core/app-navigation/domain/PagesConsts";
import FactoryImpl from "./FactoryImpl";
import ModalConfirm from "./components/internalApp/controls/ModalConfirm.vue";
import SplashScreen from "./components/internalApp/controls/SplashScreen.vue";

export default defineComponent({
  name: "App",
  components: { Login, InternalApp, ModalConfirm, SplashScreen },
  setup() {
    const factory = new FactoryImpl();
    provide(FACTORY_KEY, factory);

    const httpClient = factory.createHTTPClient();
    provide(HTTP_CLIENT_KEY, httpClient);

    const appPLOC = factory.createAppPLOC(httpClient);
    const appState = manageSubscription(appPLOC);
    provide(APP_PLOC_KEY, appPLOC);

    const loginPLOC = factory.createLoginPLOC(httpClient);
    provide(LOGIN_PLOC_KEY, loginPLOC);

    const onSessionExpiredOk = () => {
      loginPLOC.resetLoginInfo();
      appPLOC.onLogout(true);
    };

    onMounted(async () => {
      await appPLOC.doStartupSessionHeartBeat();
      appPLOC.retrieveCurrentPage();
      window.onhashchange = appPLOC.onHashChange.bind(appPLOC);
    });

    const getLoginPageId = function () {
      return LOGIN_PAGE_ID;
    };

    const getSplashScreenPageId = () => {
      return SPLASH_SCREEN_PAGE_ID;
    };

    return { appState, getLoginPageId, onSessionExpiredOk, getSplashScreenPageId };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

html {
  height: 100%;

  body {
    margin: 0px;
    height: 100%;
  }

  #app {
    height: 100%;
  }

  #app,
  input {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .btn {
    border-width: 0;
    outline: none;
    line-height: 1.538;
    padding: 9px 20px;
    border-radius: 0px;
    transition: none;
    min-height: $input-height-base;
    width: 100%;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 0.875rem;
    font-family: inherit;

    &:active,
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .btn-outline {
    padding: 0.4rem 1rem;
    line-height: 1.657;
    border-width: 2px;
    background-color: transparent;
    font-weight: 500;
    border-color: rgba(0, 200, 83, 0.5);
    width: fit-content;
    border-radius: 4px;
  }

  .btn-outline-confirm {
    @include btn-outline(#41b883);
  }
  .btn-outline-remove {
    @include btn-outline(#ff6a6a);
  }

  .btn-outline-discard {
    @include btn-outline(#7987a1);
  }

  .btn-outline-filter {
    @include btn-outline(#ffc70e);
  }

  .btn-secondary {
    color: #fff;
    background-color: $color-secondary;
    border-color: #1a53ff;
    &:hover {
      color: #fff;
      background-color: #0d49ff;
      border-color: #0039e6;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;
  }

  .form-control-thin {
    @include form-control-thin-styles;
  }

  .form-control-large {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $gray-300;
    border-radius: 0px;
    height: $input-height-base;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: inherit;
    color: $gray-900;
    font-weight: 500;
    border-width: 2px;
    border-color: $gray-300;

    &:focus {
      border-color: $gray-400;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: $gray-500;
      font-size: 1em;
    }

    &:focus {
      border-color: #b4bdce;
      box-shadow: none;
      outline: 0;
    }
  }

  .error {
    color: red;
    font-weight: 400;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .modal-content {
      background-color: white;
      margin: auto;
      color: $color-primary;

      .modal-close {
        font-size: 1.3em;
        cursor: pointer;
      }

      .modal-body {
        font-size: 0.875rem;
      }
    }
  }
}

.switch-field {
  display: flex;
  overflow: hidden;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background-color: $selected-color;
      box-shadow: none;
      color: white;
    }
  }
  label {
    background-color: $gray-200;
    color: $gray-600;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 7px;
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
}

* {
  box-sizing: border-box;
}

html {
  --scrollbarBG: #f4f5f8;
  --thumbBG: #b4bdce;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body {
  h4 {
    font-size: 1.3125rem;
  }
  h3 {
    font-size: 1.3rem;
  }
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border: 1px solid var(--scrollbarBG);
  border-radius: 6px;
}
</style>
