import { sleep } from "@/core/common/utils/GenericUtils";
import { PLOC } from "../../common/presentation/PLOC";
import { FactoryNavigationGateway } from "../domain/FactoryNavigationGateway";
import { CALENDAR_PAGE_ID, LOGIN_PAGE_ID, SPLASH_SCREEN_PAGE_ID } from "../domain/PagesConsts";
import SessionHeartBeat from "../domain/SessionHeartBeat";
import DoSingleSessionHeartBeatUseCase from "../domain/usecases/DoSingleSessionHeartBeatUseCase";
import getCurrentPageTitleUseCase from "../domain/usecases/GetCurrentPageTitleUseCase";
import { PageChangeUseCase } from "../domain/usecases/PageChangeUseCase";
import { RetrieveCurrentPageUseCase } from "../domain/usecases/RetrieveCurrentPageUseCase";
import { WebEnvGateway } from "../domain/WebEnvGateway";
import ISessionListener from "../sessionWorker/SessionListerner";
import WorkerManager from "../sessionWorker/WorkerManager";
import { AppState, appInitialState } from "./AppState";
export class AppPLOC extends PLOC<AppState> {
  private webEnvGW: WebEnvGateway;
  private factory: FactoryNavigationGateway;
  private sessionHeartBeat: SessionHeartBeat;
  private sessionWorkerManager: WorkerManager;
  private sessionListener: ISessionListener;

  constructor(webEnvGW: WebEnvGateway, factory: FactoryNavigationGateway, sessionHeartBeat: SessionHeartBeat) {
    super(appInitialState);
    this.webEnvGW = webEnvGW;
    this.factory = factory;
    this.sessionHeartBeat = sessionHeartBeat;
    this.sessionListener = {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
      onSessionKo: () => {
        this.sessionWorkerManager.terminate();
        this.changeState({ ...this.state, sessionExpired: true });
      },
    };
    this.sessionWorkerManager = new WorkerManager(this.sessionListener);
  }

  public retrieveCurrentPage(): void {
    const lCurrentPage = RetrieveCurrentPageUseCase.retrieveCurrentPage(
      this.webEnvGW,
      this.factory,
      this.state.loggedIn
    );
    this.changeState({
      ...this.state,
      currentPage: lCurrentPage,
      isIconbarOpen: lCurrentPage === CALENDAR_PAGE_ID,
    });
  }

  public onPageChange(newCurrentPageId: string) {
    const pageChangeResult = PageChangeUseCase.explicitPageChange(
      this.webEnvGW,
      this.factory,
      (<AppState>this.state).currentPage,
      newCurrentPageId,
      this.state.loggedIn
    );
    if (pageChangeResult.isPageChanged)
      this.changeState({
        ...this.state,
        currentPage: pageChangeResult.pageId,
        isIconbarOpen: pageChangeResult.pageId === CALENDAR_PAGE_ID,
      });
  }

  public onHashChange() {
    const pageChangeResult = PageChangeUseCase.pageChangeByHash(
      this.webEnvGW,
      this.factory,
      (<AppState>this.state).currentPage,
      this.state.loggedIn
    );
    if (pageChangeResult.isPageChanged)
      this.changeState({
        ...this.state,
        currentPage: pageChangeResult.pageId,
        isIconbarOpen: pageChangeResult.pageId === CALENDAR_PAGE_ID,
      });
  }

  public getCurrentPageTitle(currentPage: string): string {
    return getCurrentPageTitleUseCase.getCurrentPageTitle(currentPage);
  }

  public async doStartupSessionHeartBeat() {
    const sessionHbResponse = await DoSingleSessionHeartBeatUseCase.doSingleSessionHeartBeat(this.sessionHeartBeat);
    if (sessionHbResponse.apiCallSuccess) this.changeState({ ...this.state, loggedIn: sessionHbResponse.sessionOk });
    else this.changeState({ ...this.state, loggedIn: false });

    //Ad ogni caricamento di pagina (F5) inizio (lazy nel caso si venga da login) il sessionHB
    if (sessionHbResponse.sessionOk) this.sessionWorkerManager.startSessionHeartBeat();
  }

  public async onLogin(loginOk: boolean) {
    //Se eseguo il login con successo allora inizio il sesisonHB
    if (loginOk) {
      this.sessionWorkerManager.startSessionHeartBeat();
    }

    this.changeState({ ...this.state, loggedIn: loginOk });

    if (loginOk) {
      this.onPageChange(SPLASH_SCREEN_PAGE_ID);
      await sleep(3000);
    }

    this.onPageChange(loginOk ? CALENDAR_PAGE_ID : LOGIN_PAGE_ID);
  }

  public onLogout(logoutOk: boolean) {
    if (logoutOk) {
      this.onPageChange(LOGIN_PAGE_ID);
      this.closeLoggedUserMenu();
      this.sessionWorkerManager.terminate();
      this.changeState({ ...this.state, loggedIn: false, sessionExpired: false });
    }
  }

  public toggleLoggedUserMenuActive() {
    this.changeState({
      ...this.state,
      loggedUserMenuActive: !this.state.loggedUserMenuActive,
    });
  }

  public closeLoggedUserMenu() {
    this.changeState({
      ...this.state,
      loggedUserMenuActive: false,
    });
  }
}
