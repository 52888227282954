<template>
  <div class="loader">
    <loading-outlined />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: { LoadingOutlined },
  props: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";
.loader {
  color: $selected-color;
  font-size: 150px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
