import { COLUMN_WIDTH, ROW_HEAD_CELL_TYPE, VALUES_SEPARATOR } from "../../common/PivotConsts";
import { getDescFromCode, valueIsValid } from "../../common/PivotUtils";
import { IPivotChunkIndex } from "../../domain/PivotDS";
import CellWidthCalculator from "./CellWidthCalculator";
import { DomDrawState } from "../common/DomDrawState";
import { IPivotOptions } from "../../domain/PivotOptionsDS";

interface ITotalInfo {
  isTotalRow: boolean;
  isSubTotalRow: boolean | 0;
}

interface IRowHeadCell {
  cssClass: string;
  textContent: string;
  pathRow: string;
  isMeasure: number | boolean;
  idx: number;
}

interface IDrawContext {
  groupFirstRow: Array<string>;
  rowUnique: Array<string>;
  isFirstRowOfGroup: boolean;
  relativeRow: number;
  pivotIndex: IPivotChunkIndex;
  totalInfo: ITotalInfo;
  opts: IPivotOptions;
  isParentOpen: boolean;
  iRowAbs: number;
  keyContainsMeasure: number;
  nDataRowsDOM: number;
  firstRowAbs: number;
  rowIdx: number;
}

export default class PivotRowHeadData {
  private pivotRowHeadDataDOM: HTMLElement;
  private rowsHeadCells: Map<string, HTMLElement>;
  private cellWidthCalculator: CellWidthCalculator;

  constructor(pivotRowHeadDataDOM: HTMLElement, cellWidthCalculator: CellWidthCalculator) {
    this.pivotRowHeadDataDOM = pivotRowHeadDataDOM;
    this.rowsHeadCells = new Map();
    this.cellWidthCalculator = cellWidthCalculator;
  }

  private calculateDomWidth() {
    const rowHeadData = DomDrawState.value().rowHeadData;
    let processedColumnsCount = 0;
    rowHeadData.domWidth = DomDrawState.value().params.opts.rows.reduce((accumulator) => {
      let currentColumnWidth = 0;
      const measuresColumn = DomDrawState.value().params.opts.measuresOnColumn ? 0 : 1;
      if (processedColumnsCount < rowHeadData.nColumns - measuresColumn) {
        currentColumnWidth = COLUMN_WIDTH || 0;
        rowHeadData.cssGridTemplate += `${currentColumnWidth}px `;
        processedColumnsCount++;
      }
      return accumulator + currentColumnWidth;
    }, 0);
    if (!DomDrawState.value().params.opts.measuresOnColumn) {
      const measuresColumnWidth = COLUMN_WIDTH || 0;
      rowHeadData.cssGridTemplate += `${measuresColumnWidth}px `;
      rowHeadData.domWidth += measuresColumnWidth;
    }
    rowHeadData.fullWidth = rowHeadData.domWidth;
  }

  public calculateWidth() {
    const nColumns = DomDrawState.value().params.pivotIndex?.maxOpenedFieldsOnRow || 0;
    const rowHeadData = DomDrawState.value().rowHeadData;

    rowHeadData.columnsChanged = nColumns !== rowHeadData.nColumns;
    rowHeadData.nColumns = nColumns;
    rowHeadData.cssGridTemplate = "";
    this.calculateDomWidth();

    const halfWidth = Math.floor(DomDrawState.value().tableSize.currentWidth / 2);
    const needToShrinkHeader =
      rowHeadData.domWidth + DomDrawState.value().dataValues.fullWidth > DomDrawState.value().tableSize.currentWidth &&
      rowHeadData.domWidth > halfWidth;
    if (needToShrinkHeader) {
      rowHeadData.domWidth = halfWidth;
    }
  }

  public setCSSTranslateProp(translateRow: number) {
    this.pivotRowHeadDataDOM.style.setProperty("transform", `translate(0px,${translateRow}px)`);
  }

  public drawStructure() {
    const createRowHeadCell = (iRow: number, jCol: number) => {
      const rowHeadCell = document.createElement("div");
      rowHeadCell.setAttribute("data-cell-type", ROW_HEAD_CELL_TYPE);
      this.rowsHeadCells.set(`${iRow}-${jCol}`, rowHeadCell);
    };
    this.rowsHeadCells.clear();
    for (let i = 0; i < DomDrawState.value().dataValues.nRowsDom; i++) {
      for (let j = 0; j < DomDrawState.value().rowHeadData.nColumns; j++) {
        createRowHeadCell(i, j);
      }
    }
    this.pivotRowHeadDataDOM.replaceChildren(...Array.from(this.rowsHeadCells.values()));
  }

  private adjustCellClass(rowHeadCell: IRowHeadCell, drawCtx: IDrawContext) {
    if (rowHeadCell.idx === 0) {
      rowHeadCell.cssClass += " first-of-row";
    }
    if (drawCtx.iRowAbs === drawCtx.pivotIndex?.allRowsLength - 1) {
      rowHeadCell.cssClass += " last-of-list";
    }
    if (rowHeadCell.isMeasure) {
      rowHeadCell.cssClass += " measure";
    } else {
      if (
        rowHeadCell.idx >= drawCtx.rowUnique.length - +!drawCtx.totalInfo.isSubTotalRow &&
        rowHeadCell.idx < drawCtx.pivotIndex.maxOpenedFieldsOnRow - 1 - drawCtx.keyContainsMeasure
      ) {
        rowHeadCell.cssClass += " no-division-border";
      }
      if (drawCtx.isFirstRowOfGroup) {
        rowHeadCell.cssClass += " new-cell";
      }
    }
  }

  private populateTotal(drawCtx: IDrawContext) {
    drawCtx.totalInfo.isTotalRow = drawCtx.rowUnique?.length === 0;
    drawCtx.totalInfo.isSubTotalRow =
      drawCtx.rowUnique?.length < drawCtx.pivotIndex.maxOpenedFieldsOnRow - drawCtx.keyContainsMeasure;
    let fieldIndex = 0;
    let subTotalKey = "";
    while (drawCtx.totalInfo.isSubTotalRow && fieldIndex < drawCtx.rowUnique.length) {
      subTotalKey += subTotalKey ? VALUES_SEPARATOR + drawCtx.rowUnique[fieldIndex] : drawCtx.rowUnique[fieldIndex];
      drawCtx.totalInfo.isSubTotalRow = drawCtx.pivotIndex.openedRows.has(subTotalKey);
      fieldIndex++;
    }
  }

  private initDraw(): IDrawContext {
    const drawCtx = {
      relativeRow: 0,
      rowUnique: new Array<string>(),
      totalInfo: {} as ITotalInfo,
      isFirstRowOfGroup: false,
      groupFirstRow: new Array<string>(),
      pivotIndex: DomDrawState.value().params.pivotIndex,
      opts: DomDrawState.value().params.opts,
      isParentOpen: false,
      keyContainsMeasure: 0,
      iRowAbs: 0,
      nDataRowsDOM: DomDrawState.value().dataValues.nRowsDom,
      firstRowAbs: DomDrawState.value().scroll.firstRowAbsolute,
      rowIdx: 0,
    };
    drawCtx.keyContainsMeasure = +!drawCtx.opts.measuresOnColumn;
    drawCtx.groupFirstRow =
      drawCtx.pivotIndex.rowsUniques?.[
        drawCtx.firstRowAbs - 1 - DomDrawState.value().chunk.chunkStartRowAbsolute
      ]?.slice(0, -drawCtx.keyContainsMeasure || undefined) || [];
    return drawCtx;
  }

  private initRowDraw(drawCtx: IDrawContext) {
    drawCtx.iRowAbs = drawCtx.rowIdx + drawCtx.firstRowAbs;
    drawCtx.relativeRow = drawCtx.iRowAbs - DomDrawState.value().chunk.chunkStartRowAbsolute;
    drawCtx.rowUnique = drawCtx.pivotIndex.rowsUniques?.[drawCtx.relativeRow]?.slice(
      0,
      -drawCtx.keyContainsMeasure || undefined
    );

    this.populateTotal(drawCtx);
    drawCtx.isFirstRowOfGroup = false;
    drawCtx.isParentOpen = true;
  }

  private initRowHeadCell(rowHeadCell: IRowHeadCell, drawCtx: IDrawContext) {
    rowHeadCell.cssClass += "cell cell-header row-head-cell";
    rowHeadCell.isMeasure =
      drawCtx.keyContainsMeasure &&
      rowHeadCell.idx === drawCtx.pivotIndex.maxOpenedFieldsOnRow - drawCtx.keyContainsMeasure;
  }

  private buildRowMeasureCell(rowHeadCell: IRowHeadCell, drawCtx: IDrawContext) {
    rowHeadCell.textContent = getDescFromCode(
      drawCtx.pivotIndex.rowsUniques?.[drawCtx.relativeRow]?.slice(-1)?.[0],
      drawCtx.pivotIndex.codeToDesc
    );
  }

  private buildRowTotalCell(rowHeadCell: IRowHeadCell, drawCtx: IDrawContext) {
    if (drawCtx.groupFirstRow.length !== drawCtx.rowUnique.length && rowHeadCell.idx >= drawCtx.rowUnique.length) {
      drawCtx.groupFirstRow = drawCtx.rowUnique;
      drawCtx.isFirstRowOfGroup = true;
    }

    if (drawCtx.isFirstRowOfGroup) {
      if (rowHeadCell.idx === drawCtx.rowUnique.length) {
        if (drawCtx.totalInfo.isTotalRow) {
          rowHeadCell.textContent = "Totale";
        } else {
          rowHeadCell.textContent = `${getDescFromCode(
            drawCtx.rowUnique.slice(-1)?.[0],
            drawCtx.pivotIndex.codeToDesc,
            drawCtx.opts.rows[drawCtx.rowUnique.length - 1].alias
          )} [${"Totale"}]`;
        }
      }
    }
  }

  private buildGenericRowCell(rowHeadCell: IRowHeadCell, drawCtx: IDrawContext) {
    if (
      drawCtx.isFirstRowOfGroup ||
      (drawCtx.groupFirstRow[rowHeadCell.idx] !== drawCtx.rowUnique[rowHeadCell.idx] &&
        valueIsValid(drawCtx.rowUnique[rowHeadCell.idx]))
    ) {
      drawCtx.groupFirstRow.splice(
        rowHeadCell.idx,
        drawCtx.pivotIndex.maxOpenedFieldsOnRow,
        drawCtx.rowUnique[rowHeadCell.idx]
      );
      drawCtx.isFirstRowOfGroup = true;
      if (rowHeadCell.idx < drawCtx.opts.rows.length - 1) {
        rowHeadCell.pathRow = drawCtx.rowUnique.slice(0, rowHeadCell.idx + 1).join(VALUES_SEPARATOR);
        let isOpen = false;
        if (drawCtx.isParentOpen) {
          isOpen = drawCtx.pivotIndex.openedRows.has(rowHeadCell.pathRow);
          drawCtx.isParentOpen = isOpen;
          rowHeadCell.cssClass += isOpen ? " open" : " close";
        }
      }
      rowHeadCell.textContent = getDescFromCode(
        drawCtx.rowUnique?.[rowHeadCell.idx],
        drawCtx.pivotIndex.codeToDesc,
        drawCtx.opts.rows[rowHeadCell.idx].alias
      );
    }
  }

  private drawCell(rowHeadCell: IRowHeadCell, rowCellDOM: HTMLElement) {
    rowCellDOM.setAttribute("class", rowHeadCell.cssClass);
    rowCellDOM.setAttribute("data-head-path", rowHeadCell.pathRow);
    rowCellDOM.textContent = rowHeadCell.textContent;
    const contentWidth = this.cellWidthCalculator.calculateCellWidth(rowHeadCell.textContent);
    if (contentWidth + CellWidthCalculator.TOLERANCE > COLUMN_WIDTH) {
      rowCellDOM.classList.add("need-tooltip");
    }
  }

  public drawData() {
    const drawCtx = this.initDraw();

    for (drawCtx.rowIdx = 0; drawCtx.rowIdx < drawCtx.nDataRowsDOM; drawCtx.rowIdx++) {
      this.initRowDraw(drawCtx);

      for (let j = 0; j < drawCtx.pivotIndex.maxOpenedFieldsOnRow; j++) {
        const rowCellDOM = this.rowsHeadCells.get(`${drawCtx.rowIdx}-${j}`);
        if (rowCellDOM) {
          const rowHeadCell: IRowHeadCell = { cssClass: "", textContent: "", pathRow: "", isMeasure: false, idx: j };
          if (drawCtx.rowUnique) {
            this.initRowHeadCell(rowHeadCell, drawCtx);

            if (rowHeadCell.isMeasure) {
              this.buildRowMeasureCell(rowHeadCell, drawCtx);
            } else if (drawCtx.totalInfo.isSubTotalRow || drawCtx.totalInfo.isTotalRow) {
              this.buildRowTotalCell(rowHeadCell, drawCtx);
            } else if (rowHeadCell.idx < drawCtx.rowUnique.length) {
              this.buildGenericRowCell(rowHeadCell, drawCtx);
            }

            this.adjustCellClass(rowHeadCell, drawCtx);
          }

          this.drawCell(rowHeadCell, rowCellDOM);
        }
      }
    }
  }
}
