import { IPivotChunkIndex } from "../../domain/PivotDS";
import { IPivotOptions } from "../../domain/PivotOptionsDS";

interface IInputParams {
  pivotIndex: IPivotChunkIndex;
  dataChanged: boolean;
  opts: IPivotOptions;
}

export interface DomDrawStateDS {
  tableSize: {
    currentHeight: number;
    currentWidth: number;
    sizeChanged: boolean;
    hasSpaceForDraw: boolean;
    observerInitialized: boolean;
  };
  params: IInputParams;
  scroll: {
    dataScrollAnimationIsRunning: boolean;
    translateColumn: number;
    translateRow: number;
    isNewRow: boolean;
    isNewColumn: boolean;
    firstRowAbsolute: number;
    firstColumnAbsolute: number;
  };
  entitiesMetadata: { showColEntitiesMetadata: boolean };
  dataValues: {
    columnsChanged: boolean;
    rowsChanged: boolean;
    fullWidth: number;
    fullHeight: number;
    nAllDataRows: number;
    nAllDataColumns: number;
    nRowsDom: number;
    nColumnsDom: number;
    domWidth: number;
  };
  colHeadData: { rowsChanged: boolean; domHeight: number; fullHeight: number; nRows: number; cssGridTemplate: string };
  rowHeadData: {
    columnsChanged: boolean;
    domWidth: number;
    cssGridTemplate: string;
    fullWidth: number;
    nColumns: number;
  };
  chunk: {
    chunkStartRowAbsolute: number;
    chunkEndRowAbsolute: number;
    chunkStartColumnAbsolute: number;
    chunkEndColumnAbsolute: number;
  };
}

export class DomDrawState {
  private static instance: DomDrawState;
  private value: DomDrawStateDS;

  public reset() {
    this.value = {
      tableSize: {
        currentHeight: 0,
        currentWidth: 0,
        sizeChanged: false,
        hasSpaceForDraw: false,
        observerInitialized: false,
      },
      params: { pivotIndex: {} as IPivotChunkIndex, opts: {} as IPivotOptions, dataChanged: false },
      scroll: {
        dataScrollAnimationIsRunning: false,
        translateColumn: 0,
        translateRow: 0,
        isNewRow: false,
        isNewColumn: false,
        firstRowAbsolute: 0,
        firstColumnAbsolute: 0,
      },
      entitiesMetadata: { showColEntitiesMetadata: false },
      dataValues: {
        columnsChanged: false,
        rowsChanged: false,
        fullWidth: 0,
        fullHeight: 0,
        nAllDataRows: 0,
        nAllDataColumns: 0,
        nRowsDom: 0,
        nColumnsDom: 0,
        domWidth: 0,
      },
      colHeadData: { rowsChanged: false, domHeight: 0, fullHeight: 0, nRows: 0, cssGridTemplate: "" },
      rowHeadData: {
        columnsChanged: false,
        domWidth: 0,
        cssGridTemplate: "",
        fullWidth: 0,
        nColumns: 0,
      },
      chunk: {
        chunkStartRowAbsolute: 0,
        chunkEndRowAbsolute: 0,
        chunkStartColumnAbsolute: 0,
        chunkEndColumnAbsolute: 0,
      },
    };
  }

  private constructor() {
    this.value = {} as DomDrawStateDS;
    this.reset();
  }

  private getValue() {
    return this.value;
  }

  public static getInstance(): DomDrawState {
    if (!DomDrawState.instance) {
      DomDrawState.instance = new DomDrawState();
    }

    return DomDrawState.instance;
  }

  public static value(): DomDrawStateDS {
    return DomDrawState.getInstance().getValue();
  }
}
